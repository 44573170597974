import React from 'react';
import { GoogleMap, LoadScript, Polygon } from '@react-google-maps/api';
import config from '../../config';


function Map({ activities, height }) {


    const filteredActivities = activities.filter(item => item.points && item.points.length);

    const getPoints = (points) => {
        const polygonPath = points.map(item => {
            const obj = {
                lat: parseFloat(item.lat),
                lng: parseFloat(item.lng),
            }
            return obj
        })
        return polygonPath
    }

    return (
        <LoadScript
            googleMapsApiKey={config.mapsKey}
        >
            <GoogleMap
                mapTypeId="satellite"
                center={{
                    lat: parseFloat(filteredActivities[0]?.points[1].lat ?? 9.0820),
                    lng: parseFloat(filteredActivities[0]?.points[1].lng ?? 8.6753),
                }}
                mapContainerStyle={{ height: height ? height : '60vh' }}
                zoom={16}
            >
                <>

                    <Polygon
                        paths={getPoints(filteredActivities[0]?.points ?? [])}
                        options={
                            {
                                fillColor: "#009688",
                                fillOpacity: 0.7,
                                strokeColor: 'black',
                                strokeOpacity: 1,
                                strokeWeight: 2,
                                clickable: true,
                                draggable: false,
                                editable: false,
                                geodesic: false,
                                zIndex: 1
                            }
                        } />
                </>
            </GoogleMap>
        </LoadScript>
    )
}

export default Map;




// AIzaSyAE5beByDMsRgJwMDLutqutRI_ZjxnyCJs