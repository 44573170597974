import {
  ChakraProvider,
  extendTheme,
  ColorModeScript
} from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import ContextProvider from './context/Context';


const colors = {
  brand: {
    lightGreen: "rgba(132, 193, 34, 0.1)",
    midGreen: 'rgba(181, 218, 122, 0.76)',
    inputBg: '#F5F6F8',
    primaryGreen: '#84C122',
    primaryGreenHover: '#76ad1e',
    lightTextColor: '#7285A5',
    greyTextColor: '#4E4B66',
    lightGreyTextColor: 'rgba(0, 0, 0, 0.45)',
  },
}


const theme = extendTheme({ colors })

ReactDOM.render(
  <StrictMode>
    <ColorModeScript />
    <ContextProvider>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </ContextProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
