const rules = {
    "super admin": {
        static: [
            "dashboard:view",
            "users:view",
            "tenants:view",
            "tenants:add",
        ]
    },
    "admin": {
        static: [
            "dashboard:view",
            "users:view",
            "users:add",
            "users:edit",
            "users:activate",
            "investments:view",
            "investments:add",
            "investments:edit",
            "settings:view",
            "investors:view"
        ],
        dynamic: {

        }
    },
    "investor": {
        static: [
            "portfolio:view",
            "investments-investor:view",
            "activities:view",
            "investors-profile:view",
        ],
        dynamic: {

        }
    },
};

export default rules;




