import * as Yup from 'yup';

export const addProjectValidationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    roi: Yup.string().required("ROI is required"),
    startDate: Yup.string().required("Start Date is required"),
    season: Yup.string().required("Season is required"),
    unitprice: Yup.string().required("Unit Price is required"),
    tenure: Yup.string().required("Tenure is required"),
    description: Yup.string().required("Description is required"),
    publishDate: Yup.string().required("Publish Date is required"),
    type: Yup.string().required("Type is required"),
})



export const addSpecialPackageValidationSchema = Yup.object({
    farmsize: Yup.string().required("Farm Size is required"),
    commission: Yup.string().required("Commission is required"),
    priceperunit: Yup.string().required("Price per unit is required"),
    publishdate: Yup.string().required("Publish Date is required"),
    windowid: Yup.string().required("Investment Window is required"),
    description: Yup.string().required("Description is required"),
    email: Yup.string().required("Email is required"),
    phone: Yup.string().required("Phone Number is required"),
    packagename: Yup.string().required("Package Title is required"),
})
