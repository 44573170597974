import React from 'react';
import {
    Flex,
    Box,
    Heading,
} from "@chakra-ui/react"
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';
import InvestorSpecials from './components/InvestorSpecials';


function UserInvestments() {

    return (
        <>
            <Flex>
                <Drawer />
                <Box w="100%">
                    <ProfileHeader />
                    <Box px={4} py={8}>
                        <Heading mb={8} color="brand.greyTextColor">Investments</Heading>
                        <InvestorSpecials />
                    </Box>
                </Box>
            </Flex>
        </>
    );
}

export default UserInvestments;