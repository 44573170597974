import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  // Tabs,
  // TabList,
  // Tab,
  // TabPanels,
  // TabPanel,
  Flex,
  Box,
  Heading,
  HStack,
  VStack,
  Spinner,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Progress,
  Spacer,
  Textarea,
  ModalCloseButton,
  Text,
  Grid,
  Select,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';
// import Projects from './components/Projects';
import Specials from './components/Specials';
import { Context } from '../../context/Context';
import axios from 'axios';
import { showToast } from '../../utils/toast';
import config from '../../config';
import { mutate } from 'swr';
// import Can from '../../utils/rbac/Can';

function Investments() {
  const { user } = useContext(Context);
  const fileInput = useRef();
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [show, setShow] = useState(false);
  //   const [displayId, setDisplayId] = useState(null);
  const [imageInfo, setImageInfo] = useState('');
  const [description, setDescription] = useState('');
  const [openForm, setOpenForm] = useState(false);
  const [allSpecialPakages, setAllSpecialPakages] = useState([]);
  const [packageId, setPackageId] = useState('');

  const uploadFile = useCallback(
    async (file, packageId) => {
      setUploading(true);
      const data = new FormData();
      data.append('file', file);
      data.append('description', description);
      data.append('investorspecialpackageid', packageId);

      const requestOptions = {
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      };

      try {
        const res = await axios.post(
          `${config.baseUrl}/uploadimagesforinvestors`,
          data,
          requestOptions
        );
        if (res.data.error) {
          showToast('error', res.data.error);
        } else {
          mutate([
            `${config.baseUrl}/fetchimagesbyspecialpackagebyid/${packageId}`,
            user.token,
          ]);
          showToast('success', 'Successfully added image');
          setImageInfo(res.data.image);
        }
      } catch (e) {
        showToast('error', e.message);
      } finally {
        setUploading(false);
      }
    },
    [user.token, description, packageId]
  );

  const getAllSpecialPakages = async () => {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    try {
      const res = await axios.get(
        `${config.baseUrl}/fetchallspecialpackages`,
        requestOptions
      );
      if (res.data.error) {
        // showToast('error', res.data.error);
      } else {
        setAllSpecialPakages(res?.data?.specialPackages);
      }
    } catch (e) {
      showToast('error', e.message);
    }
  };

  useEffect(() => {
    getAllSpecialPakages();
  }, [description]);

  return (
    <>
      <Flex>
        <Drawer />
        <Box w="100%">
          <ProfileHeader />
          <Box px={4} py={4}>
            <Heading as="h4" size="md">
              Investments
            </Heading>
          </Box>
          <Box px={4} py={8}>
            <Flex justify="flex-end">
              <HStack>
                {/* <Link to="/investments/projects/add"> */}
                <Button
                  bg="brand.primaryGreen"
                  borderColor="brand.primaryGreen"
                  color="white"
                  _active={{ bg: 'brand.primaryGreen' }}
                  _hover={{ bg: 'brand.primaryGreenHover' }}
                  size="md"
                  onClick={() => setOpenForm(true)}
                >
                  Add Image
                </Button>
                {/* </Link> */}
                <Link to="/investments/special-packages/add">
                  <Button
                    bg="brand.primaryGreen"
                    borderColor="brand.primaryGreen"
                    color="white"
                    _active={{ bg: 'brand.primaryGreen' }}
                    _hover={{ bg: 'brand.primaryGreenHover' }}
                    size="md"
                  >
                    Add Special Package
                  </Button>
                </Link>
              </HStack>
            </Flex>

            <Modal isOpen={openForm} onClose={() => setOpenForm(false)}>
              <ModalOverlay />

              <ModalContent>
                <ModalHeader>Upload Image</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Box>
                    <Textarea
                      onChange={e => setDescription(e.target.value)}
                      placeholder="Add Image Description"
                      size="sm"
                    />
                  </Box>
                  <Box w="100%">
                    <Select
                      placeholder="Select Package"
                      size="lg"
                      w="100%"
                      mt={5}
                      value={packageId}
                      onChange={e => {
                        setPackageId(e.target.value);
                        // console.log(e.target.value);
                      }}
                      required
                    >
                      {allSpecialPakages &&
                        allSpecialPakages.map((data, index) => (
                          <option key={index} value={data.id}>
                            {data.packagename}
                          </option>
                        ))}
                      {/* <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option> */}
                    </Select>
                  </Box>
                  <Box>
                    <Button
                      w="100%"
                      mt={5}
                      onClick={() => fileInput.current.click()}
                      disabled={packageId === ''}
                    >
                      Upload
                    </Button>
                    <input
                      type="file"
                      onChange={e => {
                        uploadFile(
                          e.target.files[0],
                          packageId
                          //   displayId.investorspecialpackageid,
                          //   displayId.userid
                        );
                        setOpenForm(false);
                      }}
                      ref={fileInput}
                      accept="image/*"
                      style={{ display: 'none' }}
                    />
                  </Box>
                  <Spacer />
                  <Box p="4"></Box>
                </ModalBody>
              </ModalContent>
            </Modal>

            <br />
            <Box boxShadow="base" pb={8}>
              {/* <Tabs variant="unstyled">
                                <TabList>
                                    <Tab _selected={{ color: "white", bg: "brand.primaryGreen" }}>Special Packages</Tab>
                                    <Tab _selected={{ color: "white", bg: "brand.primaryGreen" }}>Projects</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                        <Specials />
                                    </TabPanel>
                                    <TabPanel>
                                        <Projects />
                                    </TabPanel>
                                </TabPanels>
                            </Tabs> */}
              <Specials />
            </Box>
          </Box>
        </Box>
      </Flex>
      <Modal isOpen={uploading}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Uploading</ModalHeader>
          <ModalBody>
            <Progress colorScheme="whatsapp" hasStripe value={progress} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Investments;
