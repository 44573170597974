import React, { useContext } from 'react';
import {
    Flex,
    Box,
    Text,
    Heading,
    HStack,
    Badge,
    Icon,
    Button,
    Center,
    Spinner
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';
import { Context } from '../../context/Context';
import { WiDayCloudyGusts } from 'react-icons/wi';
import { useInvestorSpecialPackages } from './helpers/fetcher';
import { formatNumber } from '../../utils/numberFormatter';


function Portfolio() {
    const { user } = useContext(Context)


    const { data: entity, isLoading, isError } = useInvestorSpecialPackages(`fetchinvestorspecialpackagesbyinvestorid/${user.id}`, user.token)


    const renderData = () => {
        if (isLoading) {
            return (
                <Box
                    mb="4"
                    p="8"
                    rounded="md"
                    w="100%"
                    minHeight="200px"

                    bgGradient="linear(to-r, rgba(181, 218, 122, 0.76), #8fd9a8)"
                >
                    <Center>
                        <Spinner size="xl" color="green.500" />
                    </Center>
                </Box>
            )
        }
        else if (isError) {
            return <Box
                mb="4"
                p="8"
                rounded="md"
                w="100%"
                minHeight="200px"

                bgGradient="linear(to-r, rgba(181, 218, 122, 0.76), #8fd9a8)"
            >
                <Text>An Error Occured</Text>
            </Box>
        }
        else if (!isError && !entity.length) {
            return <Box
                mb="4"
                p="8"
                rounded="md"
                w="100%"
                minHeight="200px"

                bgGradient="linear(to-r, rgba(181, 218, 122, 0.76), #8fd9a8)"
            >
                <Center>
                    <Text>No Data</Text>
                </Center>
            </Box>
        }
        else {
            return <>{entity.map(data => {
                const specialPackage = data.specialPackageObject;
                return (
                    <Box
                        key={data.id}
                        mb="4"
                        p="8"
                        rounded="md"
                        w="100%"
                        minHeight="200px"

                        bgGradient="linear(to-r, rgba(181, 218, 122, 0.76), #8fd9a8)"
                    >
                        <Flex justify="space-between">
                            <Heading color="brand.greyTextColor">{specialPackage.packagename}</Heading>
                            <Box>
                                <Heading color="brand.greyTextColor" as="h3" size="lg">
                                    N{formatNumber(specialPackage.priceperunit)}
                                </Heading>
                                <Heading as="h4" size="md">
                                    Per Unit
                                </Heading>
                            </Box>
                        </Flex>
                        <HStack>
                            <Text fontSize="2xl">ROI / Commission</Text>
                            <Badge colorScheme="green" fontSize="xl" rounded="md" >{specialPackage.commission}%</Badge>
                            {/* <Text fontSize="3xl">1.25%</Text> */}
                        </HStack>
                        <br />
                        <Flex justify="space-between">
                            <HStack>
                                <Icon as={WiDayCloudyGusts} w={12} h={12} color="brand.greyTextColor" />
                                <Text fontSize="xl">{specialPackage.season}</Text>
                            </HStack>
                            <Link to={{ pathname: `/dashboard/farm-activities/${specialPackage.id}` }}>
                                <Button
                                    colorScheme="green"
                                    size="md">
                                    View Farm
                                </Button>
                            </Link>
                        </Flex>
                    </Box>
                );
            })}</>
        }
    }


    return (
        <>
            <Flex>
                <Drawer />
                <Box w="100%">
                    <ProfileHeader />
                    <Box px={4} py={4}>
                        <Heading as="h4" size="md">Welcome {user.firstname}</Heading>
                    </Box>
                    <Box px={4} py={8}>
                        <br />
                        <Flex>
                            <Box w="100%" h="80vh" overflowY="scroll" shadow="md">
                                {renderData()}

                            </Box>
                        </Flex>
                    </Box>

                </Box>
            </Flex>
        </>
    );
}

export default Portfolio;
