import { createStandaloneToast } from "@chakra-ui/react"
const toast = createStandaloneToast()
// const customToast = createStandaloneToast({ theme: yourCustomTheme })


export const showToast = (status, message) => {
    toast({
        title: status,
        description: message,
        status: status.toLowerCase(),
        duration: 3000,
        isClosable: true,
        position: 'top-right'
    })
}