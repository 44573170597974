import React, {
  useMemo,
  useEffect,
  useCallback,
  useState,
  useContext,
} from 'react';
import axios from 'axios';
import { showToast } from '../../../utils/toast';
import config from '../../../config';
import { Context } from '../../../context/Context';
import {
  Avatar,
  Heading,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Divider,
  VStack,
  Spinner,
  Center,
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Grid,
  Button,
} from '@chakra-ui/react';
import { useTable } from 'react-table';
import ActivityCell from './ActivityCell';

const ActivitiesTable = ({ loading, tasks, activities }) => {
  const { user } = useContext(Context);
  const token = user.token;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activityDetails, setActivityDetails] = useState(null);
  const [toggle, setToggle] = useState(false)

  const getActivityDetails = async (task, farmer) => {
    const body = { task, farmer };

    const headers = { headers: { Authorization: `Bearer ${token}` } };

    try {
      const res = await axios.post(
        `${config.baseUrl}/activityfetch`,
        body,
        headers
      );
      if (res.data.error) {
        showToast('error', res.data.error);
      }
      setActivityDetails(res.data?.[0]?.entity ?? []);
      return res.data;
    } catch (e) {
      showToast('error', e.messager);
    }
  };

  // const showImage = () => {
  //   setToggle(!toggle)
  // }

  // console.log(getActivityDetails())

  useEffect(() => { }, [isOpen]);

  const showModal = useCallback(
    (task, farmerId) => {
      getActivityDetails(task, farmerId);

      if (isOpen) {
        return;
      }
      onOpen();
    },
    [onOpen, isOpen]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Farmer',
        accessor: `farmer`,
        Cell: ({ value }) => {
          return (
            <HStack spacing={2}>
              <Avatar size="xs" />
              <span>
                {value.first_name} {value.last_name}
              </span>
            </HStack>
          );
        },
      },

      ...tasks.map((task, i) => {
        return {
          Header: task.task,
          accessor: `${i + 1}`,
          Cell: ({ value, row }) => {
            const { original } = row;
            const farmerId = original.farmer.farmer_id;
            return (
              <ActivityCell
                value={value}
                onClick={() => showModal(task.task, farmerId)}
              />
            );
          },
        };
      }),
    ],
    [tasks, showModal]
  );

  const data = useMemo(() => activities, [activities]);

  const tableInstance = useTable({ columns, data });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <Box w="75vw" overflowX="auto">
      <Table variant="simple" colorScheme="gray" size="md" {...getTableProps()}>
        <Thead>
          {headerGroups.map(headerGroup => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map(row => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <Tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map(cell => {
                      // Apply the cell props
                      return (
                        <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                      );
                    })
                  }
                </Tr>
              );
            })
          }
        </Tbody>
      </Table>
      {loading && (
        <VStack mt={4}>
          <Spinner color="brand.primaryGreen" />
        </VStack>
      )}
      {!loading && activities.length === 0 && <Center>No records found</Center>}
      <Box>
        <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Activity Card</ModalHeader>
            <ModalCloseButton />
            {!toggle ? (<ModalBody pb={8}>
              <Grid templateColumns="repeat(4, 1fr)" gap={6} py={4}>
                <Box w="100%">
                  <Heading as="h5" size="sm">
                    Activity
                  </Heading>
                </Box>
                <Box w="100%">
                  <Stack>
                    <Heading as="h6" size="xs">
                      Category
                    </Heading>
                    <Text fontSize="sm">
                      {activityDetails?.category?.category}
                      {console.log("activityDetails", activityDetails)}
                    </Text>
                  </Stack>
                </Box>
                <Box w="100%">
                  <Stack>
                    <Heading as="h6" size="xs">
                      Activity Name
                    </Heading>
                    {/* <Text fontSize="sm">Nursery Establishment</Text> */}
                    <Text fontSize="sm">{activityDetails?.task?.task}</Text>
                  </Stack>
                </Box>
                <Box w="100%">
                  <Stack>
                    <Heading as="h6" size="xs">
                      Status
                    </Heading>
                    <ActivityCell value={activityDetails?.status} />

                  </Stack>
                </Box>
              </Grid>
              <Divider />
              <Grid templateColumns="repeat(4, 1fr)" gap={6} py={4}>
                <Box w="100%">
                  <Heading as="h5" size="sm">
                    Farm Lot
                  </Heading>
                </Box>
                <Box w="100%">
                  <Stack>
                    <Heading as="h6" size="xs">
                      Crop
                    </Heading>
                    <Text fontSize="sm">
                      {activityDetails?.crop?.crop_name}
                    </Text>
                  </Stack>
                </Box>
                <Box w="100%">
                  <Stack>
                    <Heading as="h6" size="xs">
                      Size
                    </Heading>
                    <Text fontSize="sm">{activityDetails?.farm?.size} Plot(s)</Text>
                  </Stack>
                </Box>
              </Grid>
              <Divider />
              <Grid templateColumns="repeat(4, 1fr)" gap={6} py={4}>
                <Box w="100%">
                  <Heading as="h5" size="sm">
                    Farmer
                  </Heading>
                </Box>
                <Box w="100%">
                  <Stack>
                    <Heading as="h6" size="xs">
                      Full Name
                    </Heading>
                    <Text fontSize="sm">
                      {activityDetails?.farmer?.first_name}
                    </Text>
                  </Stack>
                </Box>
                <Box w="100%">
                  <Stack>
                    <Heading as="h6" size="xs">
                      Phone Number
                    </Heading>
                    <Text fontSize="sm">{activityDetails?.farmer?.mobile}</Text>
                  </Stack>
                </Box>
              </Grid>
              <Divider />
              <Grid templateColumns="repeat(4, 1fr)" gap={6} py={4}>
                <Box w="100%">
                  <Heading as="h5" size="sm">
                    Agent
                  </Heading>
                </Box>
                <Box w="100%">
                  <Stack>
                    <Heading as="h6" size="xs">
                      Full Name
                    </Heading>
                    <Text fontSize="sm">
                      {activityDetails?.agent?.first_name +
                        ' ' +
                        activityDetails?.agent?.last_name}
                    </Text>
                  </Stack>
                </Box>
                <Box w="100%">
                  <Stack>
                    <Heading as="h6" size="xs">
                      Phone Number
                    </Heading>
                    <Text fontSize="sm">{activityDetails?.agent?.mobile}</Text>
                  </Stack>
                </Box>
              </Grid>
            </ModalBody>) :
              (<ModalBody>
                {activityDetails ? activityDetails?.images?.map((image, id) =>
                (
                  <Box key={id} mt={8} w="100%">
                    <img src={image.image} alt="" />
                  </Box>
                )
                ) : (
                  <Box w="100%">
                    <Stack>
                      <Heading as="h6" size="xs">
                        No image found
                      </Heading>
                    </Stack>
                  </Box>
                )}
              </ModalBody>)}
            <ModalFooter>
              <HStack>
                {/* <Button onClick={onClose}>Close</Button>
                <Button colorScheme="green" onClick={showImage}>
                  {!toggle ? "View Images" : "View Details"}
                </Button> */}
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
};

export default ActivitiesTable;
