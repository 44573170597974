import React, { useContext } from 'react';
import {
    Flex,
    Box,
    Button,
    HStack,
    VStack,
    Heading
} from "@chakra-ui/react"
import { Formik, Form } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { BiArrowBack } from 'react-icons/bi'
import Drawer from '../layout/Drawer';
import FormikControl from '../../utils/form/FormikControl';
import { addWindowValidationSchema } from './data/validation';
import ProfileHeader from '../layout/ProfileHeader';
import { showToast } from '../../utils/toast';
import config from '../../config';
import { Context } from '../../context/Context';
import { useSeasons } from '../../utils/data/fetcher';
import { useWindow } from './data/fetcher';


const initialValues = {
    title: '',
    seasonid: '',
    startdate: '',
    duedate: '',
    payoutdate: '',
    tenure: ''
}

function EditInvestmentWindow() {

    const location = useLocation()

    const { user } = useContext(Context);

    const history = useHistory()

    const windowId = location.pathname.split('/')[4]

    console.log(windowId)


    const { seasons, isLoadingSeasons } = useSeasons('fetchseasons', user.token)
    const { data: window } = useWindow(`getwindowbyid/${windowId}`, user.token)



    const savedValues = Object.entries(window).length > 0 ? {
        title: window.title ?? '',
        seasonid: window.seasonid ?? '',
        startdate: window.startdate ?? '',
        payoutdate: window.payoutdate ?? '',
        tenure: window.tenure ?? '',
        duedate: window.duedate ?? ''
    } : initialValues

    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` }
    }

    const onSubmit = async (values, onSubmitProps) => {

        const season = seasons.find(season => season.id === parseInt(values.seasonid))

        const body = { ...values, season: season.season, id: windowId }

        try {
            const res = await axios.post(`${config.baseUrl}/editwindow`, body, requestOptions)
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully edited window")
                history.goBack()
            }
        }
        catch (e) {
            showToast("error", e.messager)
        }
        finally {
            onSubmitProps.setSubmitting(false)
        }

    }
    return (
        <>
            <Flex>
                <Drawer />
                <Box w="100%">
                    <ProfileHeader />
                    <Box px={4} py={8}>
                        <HStack>
                            <Button colorScheme="teal" variant="link" onClick={() => history.goBack()}>
                                <BiArrowBack />
                            </Button>
                            <Heading as="h6" size="sm">Add Investment Window</Heading>
                        </HStack>
                        <br />
                        <Box w="50%">
                            <Formik
                                enableReinitialize={true}
                                initialValues={savedValues}
                                validationSchema={addWindowValidationSchema}
                                onSubmit={onSubmit}
                            >
                                {(formik) => (
                                    <Form>
                                        <VStack spacing={6}>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    name="title"
                                                    placeholder="Enter title"
                                                    label="Title"
                                                />
                                            </Box>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="select"
                                                    label="Season"
                                                    name="seasonid"
                                                    placeholder="Select Season"
                                                    optionName="season"
                                                    value="id"
                                                    isLoading={isLoadingSeasons}
                                                    options={seasons}
                                                    required
                                                />
                                            </Box>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="date"
                                                    name="startdate"
                                                    placeholder="Enter date"
                                                    label="Start Date"
                                                />
                                            </Box>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="date"
                                                    name="duedate"
                                                    placeholder="Enter date"
                                                    label="Due Date"
                                                />
                                            </Box>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    name="tenure"
                                                    placeholder="Enter tenure"
                                                    label="Tenure"
                                                />
                                            </Box>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="date"
                                                    name="payoutdate"
                                                    placeholder="Enter date"
                                                    label="Payout Date"
                                                />
                                            </Box>

                                            <Flex justify="center">
                                                <Box w="100%">
                                                    <Button
                                                        disabled={formik.isSubmitting}
                                                        isLoading={formik.isSubmitting}
                                                        loadingText="Submitting"
                                                        type="submit"
                                                        bg="brand.primaryGreen"
                                                        borderColor="brand.primaryGreen"
                                                        color="white"
                                                        _active={{ bg: "brand.primaryGreen" }}
                                                        _hover={{ bg: "brand.primaryGreenHover" }}
                                                        size="lg">
                                                        Submit
                                                </Button>
                                                </Box>
                                            </Flex>
                                        </VStack>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                </Box>
            </Flex>
        </>
    );
}

export default EditInvestmentWindow;