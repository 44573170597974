import React, { useContext } from 'react';
import {
    Grid,
    GridItem,
    Flex,
    Box,
    Heading,
    // Divider,
    Progress,
    Text,
    Tabs,
    TabList,
    // Tab,
    TabPanels,
    TabPanel,
    Button
} from "@chakra-ui/react"
import Drawer from '../layout/Drawer';
// import DoughnutChart from './Doughnut';
// import LineChart from './Line';
import ProfileHeader from '../layout/ProfileHeader';
import { Context } from '../../context/Context';
import { useDashboardData } from './helpers/fetcher';

const Dashboard = () => {

    const { user } = useContext(Context);

    const { data: dashboardData } = useDashboardData(`dashboardcounters`, user.token)
    return (
        <>
            <Flex>
                <Drawer />
                <Box w="100%">
                    <ProfileHeader />
                    <Box w="100%" px={4} py={8}>
                        <Grid
                            minHeight="80vh"
                            templateRows="repeat(3, 1fr)"
                            templateColumns="repeat(5, 1fr)"
                            gap={4}
                        >
                            <GridItem rowSpan={3} colSpan={3} shadow="lg">
                                <Box px="4">
                                    <Flex justify="space-between">
                                        <Heading as="h6" size="sm">{dashboardData?.completed?.packages ?? 0} project(s) completed out of 10</Heading>
                                        {/* <Text fontSize="sm">Show <span style={{ color: 'blue' }}>this week</span></Text> */}
                                    </Flex>
                                    <br />
                                    <Progress value={80} colorScheme="whatsapp" size="xs" />
                                    <br />
                                    <Tabs variant="unstyled">
                                        <TabList>
                                            {/* <Tab>Special Packages</Tab> */}
                                        </TabList>
                                        <TabPanels>
                                            <TabPanel p="0">
                                                <Box shadow="md" mt="4" p="4">
                                                    <Heading as="h6" size="sm">Completed Projects</Heading>
                                                    <Text fontSize="sm">{dashboardData?.completed?.packages ?? 0} Package(s)</Text>
                                                    <br />
                                                    <Flex justify="space-between" align="center">
                                                        <Text fontSize="sm">{dashboardData?.completed?.units ?? 0} unit(s)</Text>
                                                        <Button disabled colorScheme="whatsapp" size="xs">
                                                            view all
                                                        </Button>
                                                    </Flex>
                                                </Box>
                                                <Box shadow="md" mt="4" p="4">
                                                    <Heading as="h6" size="sm">Ongoing Projects</Heading>
                                                    <Text fontSize="sm">{dashboardData?.ongoing?.packages ?? 0} Package(s)</Text>
                                                    <br />
                                                    <Flex justify="space-between" align="center">
                                                        <Text fontSize="sm">{dashboardData?.completed?.units ?? 0} unit(s)</Text>
                                                        <Button disabled colorScheme="orange" size="xs">
                                                            view all
                                                        </Button>
                                                    </Flex>
                                                </Box>
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                </Box>
                            </GridItem>
                            {/* <GridItem colSpan={2} rowSpan={1} shadow="lg">
                                <Box px="4">
                                    <Flex justify="space-between">
                                        <Heading as="h6" size="sm">Investments</Heading>
                                        <Text fontSize="sm">Show <span style={{ color: 'blue' }}>this month</span></Text>
                                    </Flex>
                                    <br />
                                    <Divider orientation="horizontal" />
                                    <br />
                                    <LineChart />
                                </Box>
                            </GridItem> */}
                            {/* <GridItem colSpan={2} rowSpan={1} shadow="lg">
                                <Box px="4">
                                    <Flex justify="space-between">
                                        <Heading as="h6" size="sm">Status</Heading>
                                        <Text fontSize="sm">Show <span style={{ color: 'blue' }}>this month</span></Text>
                                    </Flex>
                                    <br />
                                    <Divider orientation="horizontal" />
                                    <br />
                                    <DoughnutChart />
                                </Box>
                            </GridItem> */}
                            {/* <GridItem colSpan={2} rowSpan={1} shadow="lg">
                                <Box px="4">
                                    <Flex justify="space-between">
                                        <Heading as="h6" size="sm">Transactions</Heading>
                                        <Text fontSize="sm">Show <span style={{ color: 'blue' }}>this month</span></Text>
                                    </Flex>
                                    <br />
                                    <Divider orientation="horizontal" />
                                    <br />
                                    <DoughnutChart />
                                </Box>
                            </GridItem> */}
                        </Grid>
                    </Box>
                </Box>
            </Flex>
        </>
    )
}

export default Dashboard;