import React, { useMemo } from 'react';
import {
    Switch,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Avatar,
    HStack,
    VStack,
    Spinner,
    Center,
    Button,
} from '@chakra-ui/react';
import { AiOutlineEdit } from 'react-icons/ai';
import { useTable } from 'react-table'
// import { Context } from '../../../context/Context';


const TenantsTable = ({ entity, loading }) => {

    const columns = useMemo(
        () => [
            {
                Header: "First Name",
                accessor: "firstname",
                Cell: ({ value }) => {
                    return (
                        <HStack spacing={2} >
                            <Avatar size="xs" />
                            <span>{value}</span>
                        </HStack >

                    )

                }

            },
            {
                Header: "Last Name",
                accessor: "lastname"
            },
            {
                Header: "Email",
                accessor: "email",

            },
            {
                Header: "Phone",
                accessor: "phone"
            },
            {
                Header: "Status",
                accessor: "isActive",
                Cell: () => {
                    return (
                        <Switch size="lg" colorScheme="whatsapp" />
                    )
                }
            },
            {
                Header: "Edit",
                accessor: "_id",
                Cell: () => {
                    return (
                        <Button >
                            <AiOutlineEdit style={{ fontSize: 18 }} />
                        </Button>
                    )

                }
            }
        ], []
    );

    const data = useMemo(() => entity || [], [entity])




    const tableInstance = useTable({ columns, data })
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance


    return (
        <>
            <Table variant="simple" colorScheme="gray" size="md" {...getTableProps()}>
                <Thead>
                    {
                        headerGroups.map(headerGroup => (
                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map(column => (
                                        <Th {...column.getHeaderProps()}>{
                                            column.render('Header')}
                                        </Th>
                                    ))
                                }
                            </Tr>

                        ))
                    }
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {// Loop over the table rows
                        rows.map(row => {
                            // Prepare the row for display
                            prepareRow(row)
                            return (
                                // Apply the row props
                                <Tr {...row.getRowProps()}>

                                    {// Loop over the rows cells
                                        row.cells.map(cell => {
                                            // Apply the cell props
                                            return (
                                                <Td {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </Td>
                                            )
                                        })
                                    }
                                </Tr>
                            )
                        })}
                </Tbody>
            </Table>
            {loading &&
                <VStack mt={4}>
                    <Spinner color="brand.primaryGreen" />
                </VStack>}
            {!loading && entity.length === 0 && <Center>No records found</Center>}
        </>
    );
}

export default TenantsTable;