import React, { useContext, useState } from 'react';
import {
    Flex,
    Box,
    Button,
    HStack,
    VStack,
    Heading,
    Grid,
    Input,
    FormLabel,
} from "@chakra-ui/react"
import { Formik, Form } from 'formik';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi'
import Drawer from '../layout/Drawer';
import FormikControl from '../../utils/form/FormikControl';
import { addSpecialPackageValidationSchema } from './helpers/validation';
import ProfileHeader from '../layout/ProfileHeader';
import { useWindows } from './helpers/fetcher';
import { Context } from '../../context/Context';
import { showToast } from '../../utils/toast';
import config from '../../config';


const initialValues = {
    farmsize: '',
    commission: '',
    priceperunit: '',
    publishdate: '',
    windowid: '',
    description: '',
    email: '',
    phone: '',
    packagename: '',
}

function AddSpecialPackage() {

    const { user } = useContext(Context);
    // const [uploading, setUploading] = useState(false)
    // const [progress, setProgress] = useState(0)
    // const [fileUrl, setFileUrl] = useState(null)
    const [selectedWindow, setSelectedWindow] = useState(null)

    const history = useHistory()


    const { data: windows, isLoading } = useWindows(`fetchwindowsfortenant`, user.token)

    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` },
        // onUploadProgress: progressEvent => {
        //     let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        //     setProgress(percentCompleted)
        // }
    }


    // const handleFilePick = (event) => {
    //     if (!event.target.files.length) return
    //     let reader = new FileReader();
    //     reader.readAsDataURL(event.target.files[0]);
    //     reader.onloadend = () => {
    //         setUploading(true)
    //         handleUpload(reader.result)
    //     };
    // }

    const onSubmit = async (values, onSubmitProps) => {

        const body = {
            ...values,
            farmsize: parseInt(values.farmsize),
            startdate: selectedWindow.startdate,
            payoutdate: selectedWindow.payoutdate,
            tenure: selectedWindow.tenure,
            duedate: selectedWindow.duedate,
        }

        try {
            const res = await axios.post(`${config.baseUrl}/createspecialpackage`, body, requestOptions)
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully added special package")
                history.goBack()
            }
        }
        catch (e) {
            showToast("error", e.messager)
        }
        finally {
            onSubmitProps.setSubmitting(false)
        }
    }

    // const handleUpload = async (file) => {
    //     setProgress(0)
    //     const body = {
    //         file
    //     }
    //     try {
    //         const res = await axios.post(`${config.baseUrl}/uploadinvestmentdocument`, body, requestOptions)
    //         if (res.data.status.code !== 100) {
    //             showToast("error", res.data.status.desc)
    //         }

    //         else {
    //             // setFileUrl(res.data.entity.logo)
    //             console.log(res.data)
    //             showToast("success", "Successfully uploaded document")
    //         }
    //     }
    //     catch (e) {
    //         showToast("error", e.messager)
    //     }
    //     finally {
    //         setUploading(false)
    //     }
    // }
    return (
        <>
            <Flex>
                <Drawer />
                <Box w="100%">
                    <ProfileHeader />
                    <Box px={4} py={8}>
                        <HStack>
                            <Button colorScheme="teal" variant="link" onClick={() => history.goBack()}>
                                <BiArrowBack />
                            </Button>
                            <Heading as="h6" size="sm">Add Special Package</Heading>
                        </HStack>
                        <br />
                        <Box w="50%">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={addSpecialPackageValidationSchema}
                                onSubmit={onSubmit}
                            >
                                {(formik) => (
                                    <Form>
                                        <VStack spacing={6} align="flex-start">
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    name="packagename"
                                                    placeholder="Enter Title"
                                                    label="Title"
                                                />
                                            </Box>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="number"
                                                    name="farmsize"
                                                    placeholder="Enter Farm Size"
                                                    label="Farm Size (Plots)"
                                                />
                                            </Box>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="number"
                                                    name="commission"
                                                    placeholder="Enter Commission"
                                                    label="ROI / Commission (%)"
                                                />
                                            </Box>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="select"
                                                    label="Investment Window"
                                                    name="windowid"
                                                    placeholder="Select Investment Window"
                                                    optionName="title"
                                                    value="id"
                                                    isLoading={isLoading}
                                                    options={windows}
                                                    required
                                                    onChange={(e) => {
                                                        const window = windows.find(window => window.id === parseInt(e.target.value));
                                                        if (!window) return
                                                        setSelectedWindow(window)

                                                    }}
                                                />
                                            </Box>

                                            {selectedWindow && <Box w="100%">
                                                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                                    <Box w="100%">
                                                        <FormLabel mb={2}>
                                                            <Heading as="a" fontSize="sm">Start Date</Heading>
                                                        </FormLabel>
                                                        <Input
                                                            disabled
                                                            type="text"
                                                            height="50px"
                                                            value={selectedWindow.startdate}

                                                            focusBorderColor="brand.primaryGreen" />
                                                    </Box>
                                                    <Box w="100%">
                                                        <FormLabel mb={2}>
                                                            <Heading as="a" fontSize="sm">Due Date</Heading>
                                                        </FormLabel>
                                                        <Input
                                                            disabled
                                                            type="text"
                                                            height="50px"
                                                            value={selectedWindow.duedate}

                                                            focusBorderColor="brand.primaryGreen" />
                                                    </Box>

                                                    <Box w="100%">
                                                        <FormLabel mb={2}>
                                                            <Heading as="a" fontSize="sm">Payout Date</Heading>
                                                        </FormLabel>
                                                        <Input
                                                            disabled
                                                            type="text"
                                                            height="50px"
                                                            value={selectedWindow.payoutdate}
                                                            focusBorderColor="brand.primaryGreen" />
                                                    </Box>

                                                    <Box w="100%">
                                                        <FormLabel mb={2}>
                                                            <Heading as="a" fontSize="sm">Tenure</Heading>
                                                        </FormLabel>
                                                        <Input
                                                            disabled
                                                            type="number"
                                                            height="50px"
                                                            value={selectedWindow.tenure}
                                                            focusBorderColor="brand.primaryGreen" />
                                                    </Box>

                                                </Grid>
                                            </Box>
                                            }

                                            <Box w="100%">

                                                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                                    <Box w="100%">
                                                        <FormikControl
                                                            control="input"
                                                            type="date"
                                                            name="publishdate"
                                                            placeholder="Enter Date"
                                                            label="Publish Date"
                                                        />
                                                    </Box>
                                                    <Box w="100%">
                                                        <FormikControl
                                                            control="input"
                                                            type="number"
                                                            name="priceperunit"
                                                            placeholder="Enter Price"
                                                            label="Price Per Unit"
                                                        />
                                                    </Box>
                                                </Grid>

                                            </Box>


                                            <Box w="100%">
                                                <FormikControl
                                                    control="textarea"
                                                    type="text"
                                                    name="description"
                                                    placeholder="Enter description"
                                                    label="Description"
                                                />
                                            </Box>
                                            {/* <Box w="100%">
                                                <FormLabel mb={2}>
                                                    <Heading as="a" fontSize="sm">Upload File</Heading>
                                                </FormLabel>
                                                <Input
                                                    type="file"
                                                    height="50px"
                                                    onChange={handleFilePick}
                                                    accept="image/*,.pdf"
                                                    focusBorderColor="brand.primaryGreen" />
                                            </Box> */}
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter Email"
                                                    label="Email"
                                                />
                                            </Box>

                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="phone"
                                                    name="phone"
                                                    placeholder="Enter Phone Number"
                                                    label="Phone Number"
                                                />
                                            </Box>
                                            <Flex justify="center">
                                                <Box w="100%">
                                                    <Button
                                                        disabled={formik.isSubmitting}
                                                        isLoading={formik.isSubmitting}
                                                        loadingText="Submitting"
                                                        type="submit"
                                                        bg="brand.primaryGreen"
                                                        borderColor="brand.primaryGreen"
                                                        color="white"
                                                        _active={{ bg: "brand.primaryGreen" }}
                                                        _hover={{ bg: "brand.primaryGreenHover" }}
                                                        size="lg">
                                                        Submit
                                                    </Button>
                                                </Box>
                                            </Flex>
                                        </VStack>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                </Box>
            </Flex>
        </>
    );
}

export default AddSpecialPackage;