import React, { useContext } from 'react';
import {
    Icon,
    HStack,
    Avatar,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Portal,
    Button,
    Text,
    List, ListItem,
    PopoverArrow, PopoverCloseButton, Box, Flex,
    Input, InputGroup, InputLeftElement
} from '@chakra-ui/react';
import { MdNotificationsNone, MdSearch } from 'react-icons/md';
import { Context } from '../../context/Context';
import { Link } from 'react-router-dom';


function ProfileHeader() {
    const { user, dispatchUser } = useContext(Context)

    const logout = () => {
        dispatchUser({
            type: 'LOG_OUT', user: {}
        });
    }
    return (
        <Flex p="4" justify="flex-end" shadow="sm">
            <HStack spacing={4}>
                <InputGroup>
                    <InputLeftElement
                        pointerEvents="none"
                        children={<MdSearch color="gray.300" />}
                    />
                    <Input
                        type="text"
                        placeholder=""
                        bg="brand.inputBg"
                        focusBorderColor="brand.primaryGreen" />
                </InputGroup>
                <Button variant="link">
                    <Icon as={MdNotificationsNone} w={7} h={7} />
                </Button>
                <Popover placement="auto-start">
                    <PopoverTrigger>
                        <Avatar bg="brand.primaryGreen" name={`${user.firstname} ${user.lastname}`} size="sm" />
                    </PopoverTrigger>
                    <Portal>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <Box p={5}>
                                <List spacing={3}>
                                    <ListItem color="brand.primaryGreen">
                                        <Link to="/profile/edit">
                                            My Profile
                                        </Link>

                                    </ListItem>
                                    <ListItem cursor="pointer" onClick={logout}>
                                        Log Out
                                    </ListItem>
                                </List>
                            </Box>
                        </PopoverContent>
                    </Portal>
                </Popover>
                <Text>{user.rolestring.toUpperCase()}</Text>
            </HStack>
        </Flex>

    )
}

export default ProfileHeader;