import React, { useContext, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Image,
  Spacer,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Drawer from '../../layout/Drawer';
import { Context } from '../../../context/Context';
import { useAllImages } from '../../dashboard/helpers/fetcher';
import moment from 'moment';
import { BiArrowBack } from 'react-icons/bi';
import ProfileHeader from '../../layout/ProfileHeader';
import config from '../../../config';
import axios from 'axios';
import { showToast } from '../../../utils/toast';
import { mutate } from 'swr';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const SpecialPackageImageGallery = () => {
  const history = useHistory();
  const { user } = useContext(Context);
  const { id } = useParams();

  const { data: images } = useAllImages(
    id ? `fetchimagesbyspecialpackagebyid/${id}` : null,
    user.token
  );
  //   console.log('fetchimagesbyspecialpackagebyid', id);

  const deleteImage = async ids => {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      const res = await axios.post(
        `${config.baseUrl}/deleteinvestorimage/${ids}`,
        // data,
        requestOptions
      );
      if (res.data.error) {
        showToast('error', res.data.error);
      } else {
        mutate([
          `${config.baseUrl}/fetchimagesbyspecialpackagebyid/${id}`,
          user.token,
        ]);
        showToast('success', 'Successfully deleted image');
        console.log('my12', res.data.image);
      }
    } catch (e) {
      showToast('error', e.message);
    }
  };

  return (
    <div>
      <Flex>
        <Drawer />
        <Box w="100%">
          <ProfileHeader />
          <Link to={`/investments`}>
            <HStack my={10}>
              <Button
                colorScheme="teal"
                variant="link"
                onClick={() => history.goBack()}
              >
                <BiArrowBack />
              </Button>
              <Heading as="h6" size="sm">
                Special Package Images
              </Heading>
            </HStack>
          </Link>
          <Wrap>
            {images.length == 0 ? (
              <>
                <Flex>
                  <Box
                    w="400px"
                    // spacing="30px"
                    m={5}
                    bg="gray.50"
                    borderRadius="md"
                    boxShadow="xs"
                    p="6"
                    rounded="md"
                  >
                    <Text>No image found</Text>
                  </Box>
                </Flex>
              </>
            ) : (
              images.map(image => (
                <>
                  <Flex key={image.id}>
                    <Box
                      w="400px"
                      // h="400px"
                      spacing="30px"
                      m={10}
                      bg="gray.550"
                      borderRadius="md"
                      boxShadow="xs"
                      p="6"
                      rounded="md"
                    >
                      <Zoom>
                        <img
                          style={{
                            marginTop: '8px',
                            verticalAlign: 'middle',
                            // width: '100%',
                            width: '600px',
                            height: '300px',
                            // borderRadius: '30px',
                          }}
                          key={image.id}
                          src={image.imageurl}
                          alt={image.imagename}
                          width="500"
                        />
                      </Zoom>
                      <Flex>
                        <Box p="2">
                          <Text>{image?.description?.toUpperCase()}</Text>
                        </Box>
                        <Spacer />
                        <Box p="2">
                          <Text color="green.500">
                            {moment(image.created_at).format('MMM Do YY')}
                          </Text>
                        </Box>
                      </Flex>
                      <Button
                        bg="red.500"
                        color="white"
                        onClick={() => deleteImage(image.id)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Flex>
                </>
              ))
            )}
          </Wrap>
        </Box>
      </Flex>
    </div>
  );
};

export default SpecialPackageImageGallery;
