import React, { useContext } from 'react';
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom';
import { Context } from './context/Context';
import ForgotPassword from './views/auth/ForgotPassword';
import ResetPassword from './views/auth/ResetPassword';
import Users from './views/users/Users';
import AddUser from './views/users/AddUser';
import AuthIndex from './views/auth/AuthIndex';
import Transactions from './views/transactions/Transactions';
import Investments from './views/investments/Investments';
import AddProject from './views/investments/AddProject';
import Investors from './views/investors/Investors';
import AddInvestor from './views/investors/AddInvestor';
import ChangePassword from './views/settings/ChangePassword';
import UserInvestments from './views/investments/UserInvestments';
import InvestmentDetails from './views/investments/InvestmentDetails';
import EditProfile from './views/profile/EditProfile';
import Tenants from './views/tenants/Tenants';
import AddTenant from './views/tenants/AddTenant';
import Portfolio from './views/dashboard/Portfolio';
import Can from './utils/rbac/Can';
import DashIndex from './views/dashboard/DashIndex';
import EditUser from './views/users/EditUser';
import InvestmentWindows from './views/windows/InvestmentWindows';
import AddInvestmentWindow from './views/windows/AddInvestmentWindow';
import EditInvestmentWindow from './views/windows/EditInvestmentWindow';
import FarmActivities from './views/dashboard/FarmActivities';
import AddSpecialPackage from './views/investments/AddSpecialPackage';
import EditSpecialPackage from './views/investments/EditSpecialPackage';
import SubscribeToPackage from './views/investments/SubscribeToPackage';
import EditInvestor from './views/investors/EditInvestor';
import PreviewInvestorUpload from './views/investors/PreviewInvestorUpload';
import SpecialPackageImageGallery from './views/investments/components/SpecialPackageImageGallery';

function App() {
  const { user } = useContext(Context);

  const CanVisitPage = ({ action, children }) => {
    return (
      <Can
        role={user.rolestring}
        perform={action}
        yes={() => children}
        no={() => (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )}
      />
    );
  };

  const PrivateRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          user.isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  };

  const UnAuthRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          !user.isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/dashboard',
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  };
  return (
    <Router>
      <Switch>
        <UnAuthRoute exact path="/">
          <AuthIndex />
        </UnAuthRoute>
        <UnAuthRoute exact path="/forgot-password">
          <ForgotPassword />
        </UnAuthRoute>
        <UnAuthRoute exact path="/reset-password/:token">
          <ResetPassword />
        </UnAuthRoute>
        <PrivateRoute exact path="/dashboard">
          <DashIndex />
        </PrivateRoute>
        <PrivateRoute exact path="/tenants">
          <CanVisitPage action="tenants:view">
            <Tenants />
          </CanVisitPage>
        </PrivateRoute>
        <PrivateRoute exact path="/tenants/add">
          <CanVisitPage action="tenants:add">
            <AddTenant />
          </CanVisitPage>
        </PrivateRoute>
        <PrivateRoute exact path="/portfolio">
          <CanVisitPage action="portfolio:view">
            <Portfolio />
          </CanVisitPage>
        </PrivateRoute>
        <Route exact path="/transactions">
          <Transactions />
        </Route>
        <PrivateRoute exact path="/user-investments">
          <CanVisitPage action="investments-investor:view">
            <UserInvestments />
          </CanVisitPage>
        </PrivateRoute>
        <PrivateRoute exact path="/user-investments/:id">
          <CanVisitPage action="investments-investor:view">
            <InvestmentDetails />
          </CanVisitPage>
        </PrivateRoute>
        <PrivateRoute exact path="/investments/:id">
          <InvestmentDetails />
        </PrivateRoute>
      </Switch>
      <PrivateRoute exact path="/users">
        <CanVisitPage action="users:view">
          <Users />
        </CanVisitPage>
      </PrivateRoute>
      <PrivateRoute exact path="/users/add">
        <CanVisitPage action="users:add">
          <AddUser />
        </CanVisitPage>
      </PrivateRoute>
      <PrivateRoute exact path="/users/edit/:id">
        <CanVisitPage action="users:edit">
          <EditUser />
        </CanVisitPage>
      </PrivateRoute>
      <PrivateRoute exact path="/settings/change-password">
        <CanVisitPage action="settings:view">
          <ChangePassword />
        </CanVisitPage>
      </PrivateRoute>
      <PrivateRoute exact path="/settings/investment-windows">
        <CanVisitPage action="settings:view">
          <InvestmentWindows />
        </CanVisitPage>
      </PrivateRoute>
      <PrivateRoute exact path="/settings/investment-windows/add">
        <CanVisitPage action="settings:view">
          <AddInvestmentWindow />
        </CanVisitPage>
      </PrivateRoute>
      <PrivateRoute exact path="/settings/investment-windows/edit/:id">
        <CanVisitPage action="settings:view">
          <EditInvestmentWindow />
        </CanVisitPage>
      </PrivateRoute>
      <PrivateRoute exact path="/profile/edit">
        <EditProfile />
      </PrivateRoute>
      <PrivateRoute exact path="/dashboard/farm-activities/:id">
        <CanVisitPage action="activities:view">
          <FarmActivities />
        </CanVisitPage>
      </PrivateRoute>
      <PrivateRoute exact path="/investors">
        <CanVisitPage action="investors:view">
          <Investors />
        </CanVisitPage>
      </PrivateRoute>
      <PrivateRoute exact path="/investors/add">
        <CanVisitPage action="investors:view">
          <AddInvestor />
        </CanVisitPage>
      </PrivateRoute>
      <PrivateRoute exact path="/investors/edit/:id">
        <CanVisitPage action="investors:view">
          <EditInvestor />
        </CanVisitPage>
      </PrivateRoute>

      <PrivateRoute exact path="/investments">
        <CanVisitPage action="investments:view">
          <Investments />
        </CanVisitPage>
      </PrivateRoute>

      <PrivateRoute exact path="/preview">
        <PreviewInvestorUpload />
      </PrivateRoute>

      <PrivateRoute exact path="/specialpackageimage/:id">
        <SpecialPackageImageGallery />
      </PrivateRoute>

      <PrivateRoute exact path="/investments/projects/add">
        <CanVisitPage action="investments:add">
          <AddProject />
        </CanVisitPage>
      </PrivateRoute>

      <PrivateRoute exact path="/investments/subscribe/:id">
        <CanVisitPage action="investments:add">
          <SubscribeToPackage />
        </CanVisitPage>
      </PrivateRoute>

      <PrivateRoute exact path="/investments/special-packages/add">
        <CanVisitPage action="investments:add">
          <AddSpecialPackage />
        </CanVisitPage>
      </PrivateRoute>
      <PrivateRoute exact path="/investments/special-packages/edit/:id">
        <CanVisitPage action="investments:add">
          <EditSpecialPackage />
        </CanVisitPage>
      </PrivateRoute>
    </Router>
  );
}

export default App;
