import React from 'react';
import { Field } from 'formik';
import { FormControl, FormLabel, Heading, Select, FormErrorMessage, Skeleton } from '@chakra-ui/react';

function ChakraSelect(props) {
    const { label, name, required, options, value, optionName, isLoading, onChange, ...rest } = props;

    return (
        <Field name={name}>
            {(props) => {
                const { field, form } = props
                return (
                    <FormControl
                        isInvalid={form.errors[name] && form.touched[name]}
                        isRequired={required ? required : false}
                    >
                        <FormLabel htmlFor={name} mb={2}>
                            <Heading as="a" fontSize="sm">{label}</Heading>
                        </FormLabel>
                        <Skeleton isLoaded={!isLoading}>
                            <Select
                                {...field}
                                {...rest}
                                id={name}
                                onChange={(e) => {
                                    form.handleChange(e);
                                    if (onChange) {
                                        onChange(e);
                                    }
                                }}
                                focusBorderColor="brand.green"
                            >
                                {
                                    options.map(item => (
                                        <option key={item[value]} value={item[value]}>{item[optionName]}</option>
                                    ))
                                }
                            </Select>
                        </Skeleton>
                        <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
                    </FormControl>
                )
            }}
        </Field>
    )
}

export default ChakraSelect;


