import * as Yup from 'yup';

export const addProjectValidationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    roi: Yup.string().required("ROI is required"),
    startDate: Yup.string().required("Start Date is required"),
    season: Yup.string().required("Season is required"),
    unitprice: Yup.string().required("Unit Price is required"),
    tenure: Yup.string().required("Tenure is required"),
    description: Yup.string().required("Description is required"),
    publishDate: Yup.string().required("Publish Date is required"),
    type: Yup.string().required("Type is required"),
})


export const editProfileValidationSchema = Yup.object({
    firstname: Yup.string().required("First Name is required"),
    middlename: Yup.string().notRequired(),
    lastname: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Email address is not valid").required("Email address is required"),
    phone: Yup.string().required("Phone Number is required"),
    residenceno: Yup.string().required("Residence Number is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    postalcode: Yup.string().notRequired(),
    gender: Yup.string().required("Gender is required"),
    dob: Yup.string().required("Date of Birth is required"),
    bvn: Yup.string().notRequired(),
    accountname: Yup.string().required("Account Name is required"),
    accountnumber: Yup.string().required("Account Number is required"),
    bankname: Yup.string().required("Bank is required"),
    nextofkin: Yup.string().notRequired(),
    nextofkinphone: Yup.string().notRequired(),
    nextofkincountry: Yup.string().notRequired(),
    nextofkinstate: Yup.string().notRequired(),
    nextofkinpostalcode: Yup.string().notRequired(),
    nextofkinresidenceno: Yup.string().notRequired(),
    nextofkinrelationship: Yup.string().notRequired(),
})






