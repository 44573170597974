import React from 'react';
import {
  Grid,
  GridItem,
  Box,
  Center,
  Image,
  VStack,
  Heading,
  Tabs,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';

import LoginImage from '../../assets/2.png';
import Logo from '../../assets/logo.png';
import Login from './Login';

const AuthIndex = () => {
  return (
    <>
      <Grid templateColumns="repeat(2, 1fr)" gap={0}>
        <GridItem colSpan={1}>
          <Box w="100%" h="100vh">
            <Image src={LoginImage} height="100vh" />
          </Box>
          <Box pos="absolute" top="230px" left="100px">
            <VStack spacing={4} align="flex-end">
              <Heading as="h4" size="4xl" color="white">
                Your journey <br /> starts here
              </Heading>
            </VStack>
          </Box>
        </GridItem>
        <GridItem colSpan={1}>
          <Center w="100%" h="100vh">
            <Box w="60%" p={4} minHeight="70vh">
              <VStack spacing={4} align="flex-end">
                {/* <Heading as="h4" size="md">
                                    Get more things done with us
                                </Heading>
                                <Text fontSize="sm">Search Sourcing the world's brightest professionals for your business.</Text> */}
                <Center w="100%">
                  <Image src={Logo} h="10%" />
                </Center>
              </VStack>
              <Box w="100%" mt={10}>
                <Tabs colorScheme="green" variant="soft-rounded">
                  {/* <TabList>
                    <Tab>Login</Tab>
                  </TabList> */}
                  <TabPanels>
                    <TabPanel p={0}>
                      <Login />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </Box>
          </Center>
        </GridItem>
      </Grid>
    </>
  );
};

export default AuthIndex;
