import React, { useContext, useState } from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Box,
  Heading,
  HStack,
  Select,
  Button,
  Stack,
  Text,
  Icon,
  Grid,
  Spacer,
} from '@chakra-ui/react';
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';
import ActivitiesTable from './components/ActivitiesTable';
import {
  useInvestorsCrops,
  useActivities,
  useTasks,
  useAllImages,
} from './helpers/fetcher';
import { Context } from '../../context/Context';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Map from './Map';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';



const CircleIcon = props => (
  <Icon viewBox="0 0 200 200" {...props}>
    <path
      fill="currentColor"
      d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
    />
  </Icon>
);

const FarmActivities = props => {
  const { user } = useContext(Context);
  const [cropData, setSelectedData] = useState(null);
  const location = useLocation();
  const specialPackageId = location.pathname.split('/')[3];

  const { data } = useInvestorsCrops(
    `fetchinvestorsfarmerswithcrops/${user.id}`,
    user.token
  );

  const { data: activities } = useActivities(
    `newactivitiesdashboard`,
    user.token
  );
  const { data: tasks, isLoading: loadingTasks } = useTasks(
    `activitytasks`,
    user.token
  );

  const { data: images } = useAllImages(
    `fetchimagesbyspecialpackagebyid/${specialPackageId}`,
    user.token
  );

  return (
    <>
      <Flex>
        <Drawer />
        <Box w="100%">
          <ProfileHeader />
          <Tabs variant="unstyled">
            <TabList>
              <Tab
                _selected={{
                  borderBottomWidth: '3px',
                  borderColor: 'brand.primaryGreen',
                }}
              >
                Farm Activities
              </Tab>
              <Tab
                _selected={{
                  borderBottomWidth: '3px',
                  borderColor: 'brand.primaryGreen',
                }}
              >
                View Images
              </Tab>
              <Tab
                _selected={{
                  borderBottomWidth: '3px',
                  borderColor: 'brand.primaryGreen',
                }}
              >
                View Map
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Heading as="h6" size="xs">
                  Farm Activities Dashboard
                </Heading>
                <Box my={8}>
                  <Flex justify="space-between" align="flex-start">
                    <HStack>
                      <Select
                        placeholder="Select crop profile"
                        onChange={e => {
                          setSelectedData(e.target.value);
                        }}
                        focusBorderColor="brand.green"
                      >
                        {data.map(
                          item =>
                            item.crop && (
                              <option key={item._id} value={item.crop._id}>
                                {item.crop.crop_name}
                              </option>
                            )
                        )}
                      </Select>
                      <Button
                        w={100}
                        variant="solid"
                        bg="brand.primaryGreen"
                        borderColor="brand.primaryGreen"
                        color="white"
                        _active={{ bg: 'brand.primaryGreen' }}
                        _hover={{ bg: 'brand.primaryGreenHover' }}
                        size="md"
                      >
                        Filter
                      </Button>
                    </HStack>
                    <Box
                      boxShadow="base"
                      px="10"
                      pb="4"
                      rounded="md"
                      bg="white"
                    >
                      <Heading as="h6" size="xs">
                        STATUS INDICATOR
                      </Heading>
                      <br />
                      <Stack spacing={2}>
                        <HStack spacing={4}>
                          <CircleIcon color="green.500" />
                          <Text fontSize="sm">Done</Text>
                        </HStack>
                        <HStack spacing={4}>
                          <CircleIcon color="orange.500" />
                          <Text fontSize="sm">Ongoing</Text>
                        </HStack>
                        <HStack spacing={4}>
                          <CircleIcon color="purple.500" />
                          <Text fontSize="sm">Skipped</Text>
                        </HStack>
                        <HStack spacing={4}>
                          <CircleIcon color="red.500" />
                          <Text fontSize="sm">Delayed</Text>
                        </HStack>
                        <HStack spacing={4}>
                          <CircleIcon color="gray.500" />
                          <Text fontSize="sm">Pending</Text>
                        </HStack>
                      </Stack>
                    </Box>
                  </Flex>
                </Box>
                <Box my={8}>
                  <Heading as="h6" size="xs">
                    FARM ACTIVITIES
                  </Heading>
                  <br />
                  <ActivitiesTable
                    loading={loadingTasks}
                    tasks={tasks}
                    activities={activities}
                  />
                </Box>
              </TabPanel>
              <TabPanel>
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      padding: '0 4px',
                    }}
                  >
                    {/* <div style={{ flex: '50%', padding: '0 4px' }}> */}
                    {/* <Grid templateColumns="repeat(3, 1fr)" gap={10}>
                      {images.map(image => (
                        <Box w="300px" h="300px">
                          <Zoom>
                            <img
                              style={{
                                marginTop: '8px',
                                verticalAlign: 'middle',
                                // width: '100%',
                                width: '600px',
                                height: '300px',
                                // borderRadius: '30px',
                              }}
                              key={image.id}
                              src={image.imageurl}
                              alt={image.imagename}
                            />
                          </Zoom>
                          <Flex>
                            <Box p="2">
                              <Text>{image.description}</Text>
                            </Box>
                            <Spacer />
                            <Box p="2">
                              <Text color="green.500">
                                {moment(image.created_at).format('MMM Do YY')}
                              </Text>
                            </Box>
                          </Flex>
                        </Box>
                      ))}
                    </Grid> */}
                    <Grid templateColumns="repeat(2, 1fr)" gap={5}>
                      {images.map(image => (
                        <>
                          {/* <Flex key={image.id}> */}
                          <Box
                            w="300px"
                            // h="400px"
                            spacing="30px"
                            m={10}
                            bg="gray.550"
                            borderRadius="md"
                            boxShadow="xs"
                            p="6"
                            rounded="md"
                          >
                            <Zoom>
                              <img
                                style={{
                                  marginTop: '8px',
                                  verticalAlign: 'middle',
                                  //   width: '100%',
                                  width: '600px',
                                  height: '220px',
                                  // borderRadius: '30px',
                                }}
                                key={image.id}
                                src={image.imageurl}
                                alt={image.imagename}
                              // width="500"
                              />
                            </Zoom>
                            <Flex>
                              <Box p="2">
                                <Text>{image?.description?.toUpperCase()}</Text>
                              </Box>
                              <Spacer />
                              <Box p="2">
                                <Text color="green.500">
                                  {moment(image.created_at).format('MMM Do YY')}
                                </Text>
                              </Box>
                            </Flex>
                          </Box>
                          {/* </Flex> */}
                        </>
                      ))}
                    </Grid>
                    {/* </div> */}
                  </div>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Map activities={activities} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
    </>
  );
};

export default FarmActivities;
