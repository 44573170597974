import React, { useContext } from 'react';
import { Context } from '../../context/Context';
import Dashboard from './Dashboard';
import Portfolio from './Portfolio';
import Can from '../../utils/rbac/Can';

const DashIndex = () => {
    const { user } = useContext(Context);

    const { rolestring } = user;
    return (
        <>
            <Can
                role={rolestring}
                perform="dashboard:view"
                yes={() => (
                    <Dashboard />
                )}
                no={() =>
                    <Portfolio />
                }
            />

        </>
    );
}

export default DashIndex;