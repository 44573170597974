import React, { useContext } from 'react';
import {
    Flex,
    Box,
    Button,
    // Input,
    // InputGroup,
    // InputLeftElement,
    Heading
} from "@chakra-ui/react";
import { Link } from 'react-router-dom';
// import { AiOutlineSearch } from 'react-icons/ai';
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';
import { Context } from '../../context/Context';
import { useWindows } from './data/fetcher';
import InvestmentWindowsTable from './components/InvestmentWindowsTable';
import Can from '../../utils/rbac/Can';

function InvestmentWindows() {
    const { user } = useContext(Context)

    const { data: entity, isLoading } = useWindows('fetchwindowsfortenant', user.token)

    return (
        <>
            <Flex>
                <Drawer />
                <Box w="100%">
                    <ProfileHeader />
                    <Box px={4} py={4}>
                        <Heading as="h4" size="md">Investment Windows</Heading>
                    </Box>
                    <Box px={4} py={4}>

                        <Flex justify="flex-end">
                            {/* <InputGroup >
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<AiOutlineSearch color="gray.300" />}
                                />
                                <Input
                                    w="40%"
                                    placeholder="Filter User"
                                    bg="brand.inputBg"
                                    focusBorderColor="brand.primaryGreen" />
                            </InputGroup> */}

                            <Can
                                role={user.rolestring}
                                perform="users:add"
                                yes={() => (
                                    <Link to="/settings/investment-windows/add">
                                        <Button
                                            bg="brand.primaryGreen"
                                            borderColor="brand.primaryGreen"
                                            color="white"
                                            _active={{ bg: "brand.primaryGreen" }}
                                            _hover={{ bg: "brand.primaryGreenHover" }}
                                            size="md">
                                            Add new investment window
                                    </Button>
                                    </Link>
                                )}
                                no={() =>
                                    null
                                }
                            />
                        </Flex>
                        <br />
                        <Box boxShadow="base" pb={8}>
                            <InvestmentWindowsTable
                                entity={entity}
                                loading={isLoading}
                            />
                        </Box>
                    </Box>
                </Box>
            </Flex>
        </>
    );
}

export default InvestmentWindows;