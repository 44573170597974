export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOG_IN':
            return state = {
                ...action.user,
                isAuthenticated: true
            };
        case 'SET_USER':
            return state = {
                ...state,
                ...action.user,
            }
        case 'LOG_OUT':
            return state = action.user;
        default:
            return state;
    }
}