import React, { useContext } from 'react';
import {
    Flex,
    Box,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    Heading
} from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';
import TenantsTable from './components/TenantsTable';
import { Context } from '../../context/Context';
import { useTenants } from './data/fetcher';

function Tenants() {

    const { user } = useContext(Context)

    const { data: entity, isLoading } = useTenants(`viewtenants`, user.token)

    return (
        <>
            <Flex>
                <Drawer />
                <Box w="100%">
                    <ProfileHeader />
                    <Box px={4} py={4}>
                        <Heading as="h4" size="md">Tenant Management</Heading>
                    </Box>
                    <Box px={4} py={4}>

                        <Flex justify="flex-end">
                            <InputGroup >
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<AiOutlineSearch color="gray.300" />}
                                />
                                <Input
                                    w="40%"
                                    placeholder="Filter Tenant"
                                    bg="brand.inputBg"
                                    focusBorderColor="brand.primaryGreen" />
                            </InputGroup>
                            <Link to="/tenants/add">
                                <Button
                                    bg="brand.primaryGreen"
                                    borderColor="brand.primaryGreen"
                                    color="white"
                                    _active={{ bg: "brand.primaryGreen" }}
                                    _hover={{ bg: "brand.primaryGreenHover" }}
                                    size="md">
                                    Add new Tenant
                                </Button>
                            </Link>
                        </Flex>
                        <br />
                        <Box boxShadow="base" pb={8}>
                            <TenantsTable
                                entity={entity}
                                loading={isLoading}
                            />
                        </Box>
                    </Box>
                </Box>

            </Flex>

        </>
    );
}

export default Tenants;