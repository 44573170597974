import React, { useState } from 'react';
import { Grid, GridItem, Box, Center, Image, VStack, Heading, Text, Button, Flex } from "@chakra-ui/react"
import { Formik, Form } from 'formik';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import { showToast } from '../../utils/toast';
import LoginImage from '../../assets/login.svg';
import { forgotPasswordValidationSchema } from './helpers/validation';
import FormikControl from '../../utils/form/FormikControl';
import config from '../../config';



const ForgotPassword = () => {
    const history = useHistory();
    const { state } = useLocation()
    const [sentLink, setSentLink] = useState(false)


    const initialValues = {
        email: state.email ?? '',
    }

    const onSubmit = async (values, onSubmitProps) => {
        try {
            const res = await axios.post(`${config.baseUrl}/forgotpassword`, values)
            if (res.data.error) {
                showToast("error", res.data.message)
            }
            else {
                setSentLink(true)
            }
        }
        catch (e) {
            showToast("error", e.message)
        }
        finally {
            onSubmitProps.setSubmitting(false)
        }
    }
    return (
        <>
            <Grid templateColumns="repeat(3, 1fr)" gap={0}>
                <GridItem colSpan={1}>
                    <Box w="100%" h="100vh">
                        <Image src={LoginImage} height="100vh" />
                    </Box>
                </GridItem>
                <GridItem colSpan={2}>
                    <Center w="100%" h="100vh">
                        <Box w="60%" p={4} minHeight="70vh">
                            <VStack spacing={4} align="flex-start">
                                <Heading as="h4" size="md">
                                    Password reset.
                                </Heading>
                                {sentLink ? <Text fontSize="sm">Password reset instructions have been sent to your email address</Text> : <Text fontSize="sm">Let’s get you a new password</Text>}

                            </VStack>

                            <Box w="100%" mt={10}>
                                {sentLink ?
                                    <Button
                                        onClick={history.goBack}
                                        bg="brand.primaryGreen"
                                        borderColor="brand.primaryGreen"
                                        color="white"
                                        _active={{ bg: "brand.primaryGreen" }}
                                        _hover={{ bg: "brand.primaryGreenHover" }}
                                        size="lg">
                                        Back to Login
                                </Button>
                                    :
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={forgotPasswordValidationSchema}
                                        onSubmit={onSubmit}
                                    >
                                        {(formik) => (
                                            <Form>
                                                <VStack spacing={6}>
                                                    <Box w="100%">
                                                        <FormikControl
                                                            control="input"
                                                            type="text"
                                                            name="email"
                                                            placeholder="Enter email address"
                                                        />
                                                    </Box>
                                                    <Flex justify="center">
                                                        <Box w="100%">
                                                            <Button
                                                                disabled={formik.isSubmitting}
                                                                isLoading={formik.isSubmitting}
                                                                loadingText="Sending..."
                                                                type="submit"
                                                                bg="brand.primaryGreen"
                                                                borderColor="brand.primaryGreen"
                                                                color="white"
                                                                _active={{ bg: "brand.primaryGreen" }}
                                                                _hover={{ bg: "brand.primaryGreenHover" }}
                                                                size="lg">
                                                                Send Reset Link
                                                        </Button>
                                                        </Box>
                                                    </Flex>
                                                </VStack>
                                            </Form>
                                        )}
                                    </Formik>
                                }
                            </Box>
                        </Box>
                    </Center>
                </GridItem>
            </Grid>
        </>
    );
}

export default ForgotPassword;