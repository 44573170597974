import useSWR from 'swr';
import config from '../../../config'
import { fetcher } from '../../../utils/data/fetcher';


export const useInvestorSpecialPackages = (path, token) => {
    const { data, error } = useSWR([path ? `${config.baseUrl}/${path}` : null, token], fetcher)
    //show error here
    return {
        data: data ? data.investorsspecialpackages : [],
        isLoading: !error && !data,
        isError: error
    }
}

export const useAllImages = (path, token) => {
    const { data, error } = useSWR([path ? `${config.baseUrl}/${path}` : null, token], fetcher)
    //show error here
    return {
        data: data?.images ?? [],
        isLoading: !error && !data,
        isError: error
    }
}

export const useInvestorImages = (path, token) => {
    const { data, error } = useSWR([path ? `${config.baseUrl}/${path}` : null, token], fetcher)
    //show error here
    return {
        data: data?.images ?? [],
        isLoading: !error && !data,
        isError: error
    }
}

export const useInvestorsCrops = (path, token) => {
    const { data, error } = useSWR([path ? `${config.baseUrl}/${path}` : null, token], fetcher)

    return {
        data: data?.data.entity ?? [],
        isLoading: !error && !data,
        isError: error
    }
}

export const useActivities = (path, token) => {
    const { data, error } = useSWR([path ? `${config.baseUrl}/${path}` : null, token], fetcher)

    return {
        data: data?.[0]?.entity ?? [],
        isLoading: !error && !data,
        isError: error
    }
}


export const useTasks = (path, token) => {
    const { data, error } = useSWR([path ? `${config.baseUrl}/${path}` : null, token], fetcher)

    return {
        data: data?.[0]?.entity?.tasks ?? [],
        isLoading: !error && !data,
        isError: error
    }
}


export const useDashboardData = (path, token) => {
    const { data, error } = useSWR([path ? `${config.baseUrl}/${path}` : null, token], fetcher)

    return {
        data,
        isLoading: !error && !data,
        isError: error
    }
}
