import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Drawer from '../layout/Drawer';
import axios from 'axios';
import { Context } from '../../context/Context';
import { showToast } from '../../utils/toast';
import config from '../../config';

const PreviewInvestorUpload = () => {
  const history = useHistory();
  const { user } = useContext(Context);
  const previewData = localStorage.getItem('previewInfo');
  const data = JSON.parse(previewData);

  const handleBulkUpload = async () => {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    try {
      const res = await axios.post(
        `${config.baseUrl}/bulkuploadusers`,
        { data: JSON.stringify(data) },
        requestOptions
      );
      if (res.data.error) {
        showToast('error', res.data.error);
      } else {
        showToast('success', 'file uploaded successfully');
        localStorage.removeItem('previewInfo');
        history.goBack();
      }
    } catch (e) {
      showToast('error', e.message);
    }
  };

  return (
    <div>
      <Flex>
        <Drawer />
        <Box w="100%" py={20} px={0}>
          <Flex justify="flex-end">
            <Button
              bg="brand.primaryGreen"
              borderColor="brand.primaryGreen"
              color="white"
              _active={{ bg: 'brand.primaryGreen' }}
              _hover={{ bg: 'brand.primaryGreenHover' }}
              size="md"
              mr={3}
              onClick={() => handleBulkUpload()}
            >
              Add All Investors
            </Button>

            {/* <BulkUpload /> */}
          </Flex>
          <Table variant="simple">
            {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
            <Thead>
              <Tr>
                <Th>NO</Th>
                <Th>First Name</Th>
                <Th>Last Name</Th>
                <Th>Email</Th>
                <Th>Address</Th>
                <Th>Phone</Th>
                <Th>State</Th>
                <Th>Country</Th>
                {/* <Th>Account Name</Th>
                <Th>Account Number</Th>
                <Th>Bank</Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {data &&
                data?.map((item, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{item.firstname}</Td>
                    <Td>{item.lastname}</Td>
                    <Td>{item.email}</Td>
                    <Td>{item.address}</Td>
                    <Td>{item.phone}</Td>
                    <Td>{item.state}</Td>
                    <Td>{item.country}</Td>
                    {/* <Td>{data.accountname}</Td>
                    <Td>{data.accountnumber}</Td>
                    <Td>{data.bankname}</Td> */}
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </Box>
      </Flex>
    </div>
  );
};

export default PreviewInvestorUpload;
