import * as Yup from 'yup';

export const addInvestorValidationSchema = Yup.object({
    firstname: Yup.string().required("First Name is required"),
    middlename: Yup.string().notRequired(),
    lastname: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Email address is not valid").required("Email address is required"),
    phone: Yup.string().required("Phone Number is required"),
    residenceno: Yup.string().notRequired(),
    // address: Yup.string().required("Address is required"),
    country: Yup.string().notRequired(),
    state: Yup.string().notRequired(),
    postalcode: Yup.string().notRequired(),
    gender: Yup.string().required("Gender is required"),
    bvn: Yup.string().notRequired(),
    accountname: Yup.string().notRequired(),
    accountnumber: Yup.string().notRequired(),
    bankname: Yup.string().notRequired(),
    nextofkin: Yup.string().notRequired(),
    nextofkinphone: Yup.string().notRequired(),
    nextofkincountry: Yup.string().notRequired(),
    nextofkinstate: Yup.string().notRequired(),
    nextofkinpostalcode: Yup.string().notRequired(),
    nextofkinresidenceno: Yup.string().notRequired(),
    nextofkinrelationship: Yup.string().notRequired(),
})

export const editInvestorValidationSchema = Yup.object({
    firstname: Yup.string().required("First Name is required"),
    middlename: Yup.string().notRequired(),
    lastname: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Email address is not valid").required("Email address is required"),
    phone: Yup.string().required("Phone Number is required"),
    residenceno: Yup.string().required("Residence Number is required"),
    // address: Yup.string().required("Address is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    postalcode: Yup.string().notRequired(),
    gender: Yup.string().required("Gender is required"),
    bvn: Yup.string().notRequired(),
    dob: Yup.string().notRequired(),
    accountname: Yup.string().notRequired(),
    accountnumber: Yup.string().notRequired(),
    bankname: Yup.string().notRequired(),
    nextofkin: Yup.string().notRequired(),
    nextofkinphone: Yup.string().notRequired(),
    nextofkincountry: Yup.string().notRequired(),
    nextofkinstate: Yup.string().notRequired(),
    nextofkinpostalcode: Yup.string().notRequired(),
    nextofkinresidenceno: Yup.string().notRequired(),
    nextofkinrelationship: Yup.string().notRequired(),
    nextofkinbvn: Yup.string().notRequired()
})




