import React from 'react';
import { Box, Center, Text } from '@chakra-ui/react';


const done = ['done', 'green.500'];
const ongoing = ['ongoing', 'orange.500'];
const skipped = ['skipped', 'purple.500'];
const delayed = ['delayed', 'red.500'];
const pending = ['pending', 'gray.500'];


const ActivityCell = ({ value, onClick }) => {


    const Cell = (valueAndColor) => (
        <Box w={20} rounded="md" bg={valueAndColor[1]} p="2" color="white" onClick={onClick ? () => onClick() : null} cursor="pointer">
            <Center>
                <Text fontSize="sm">{valueAndColor[0]}</Text>
            </Center>
        </Box>
    )
    switch (value) {
        case done[0]:
            return Cell(done)
        case ongoing[0]:
            return Cell(ongoing)
        case skipped[0]:
            return Cell(skipped)
        case delayed[0]:
            return Cell(delayed)
        case pending[0]:
            return Cell(pending)
        default: return <></>;

    }

}

export default ActivityCell;
