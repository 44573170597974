import React, { useContext } from 'react';
import { Box, VStack, Button, Flex, Text, FormLabel } from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import axios from 'axios';
import { loginValidationSchema } from './helpers/validation';
import FormikControl from '../../utils/form/FormikControl';
import { showToast } from '../../utils/toast';
import config from '../../config';
import { Context } from '../../context/Context';



const initialValues = {
    email: '',
    password: '',
}


const Login = () => {
    const { dispatchUser } = useContext(Context)

    const onSubmit = async (values, onSubmitProps) => {
        try {
            const res = await axios.post(`${config.baseUrl}/login`, values)
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                const user = { ...res.data.user, token: res.data.token }
                dispatchUser({
                    type: 'LOG_IN', user: user
                });
            }
        }
        catch (e) {
            showToast("error", e.messager)
        }
        finally {
            onSubmitProps.setSubmitting(false)
        }

    }
    return (
        <>
            <Box mt={10}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={loginValidationSchema}
                    onSubmit={onSubmit}
                >
                    {(formik) => (
                        <Form>
                            <VStack spacing={6}>
                                <Box w="100%">
                                <FormLabel htmlFor="email">Username</FormLabel>
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        name="email"
                                        // placeholder="Enter email address"
                                        bg="brand.inputBg"
                                        p={7}
                                    />
                                </Box>
                                <Box w="100%">
                                <FormLabel htmlFor="password">Password</FormLabel>
                                    <FormikControl
                                        control="input"
                                        type="password"
                                        name="password"
                                        // placeholder="Enter password"
                                        bg="brand.inputBg"
                                        p={7}
                                    />
                                </Box>
                                <Link to={{
                                    pathname: "/forgot-password",
                                    state: { email: formik.values.email }
                                }}>
                                    <Text color="brand.primaryGreen">Forgot your password? We got you</Text>
                                </Link>

                                <Flex justify="center">
                                    <Box w="100%">
                                        <Button
                                            disabled={formik.isSubmitting}
                                            isLoading={formik.isSubmitting}
                                            loadingText="Logging In"
                                            type="submit"
                                            bg="brand.primaryGreen"
                                            borderColor="brand.primaryGreen"
                                            color="white"
                                            _active={{ bg: "brand.primaryGreen" }}
                                            _hover={{ bg: "brand.primaryGreenHover" }}
                                            size="lg">
                                            Log In
                                    </Button>
                                    </Box>
                                </Flex>
                            </VStack>
                        </Form>
                    )}
                </Formik>
            </Box>

        </>
    );
}

export default Login;