import React, { useRef, useState, useContext } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Progress,
  Box,
  Spacer,
  ModalCloseButton,
  ModalFooter,
} from '@chakra-ui/react';
import axios from 'axios';
import { Context } from '../../context/Context';
import { showToast } from '../../utils/toast';
import config from '../../config';
import { useHistory } from 'react-router-dom';

const BulkUpload = () => {
  const history = useHistory();
  const { user } = useContext(Context);
  const fileInput = useRef();
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [previewInfo, setPreviewInfo] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [file, setFile] = useState(null);

  const handlePreview = async e => {
    setFile(e.target.files[0]);
    setUploading(true);

    const data = new FormData();
    data.append('file', e.target.files[0]);

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
    };

    try {
      const res = await axios.post(
        `${config.baseUrl}/previewinvestorsexcel`,
        data,
        requestOptions
      );
      if (res.data.error) {
        showToast('error', res.data.error);
      } else {
        setPreviewInfo(res?.data?.data);
        localStorage.setItem('previewInfo', JSON.stringify(res?.data?.data));
        showToast('success', 'file uploaded successfully');
      }
    } catch (e) {
      showToast('error', e.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <Button
        bg="brand.primaryGreen"
        borderColor="brand.primaryGreen"
        color="white"
        _active={{ bg: 'brand.primaryGreen' }}
        _hover={{ bg: 'brand.primaryGreenHover' }}
        size="md"
        onClick={() => setOpenForm(true)}
      >
        Upload Investors
      </Button>
      <Modal isOpen={openForm} onClose={() => setOpenForm(false)}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Upload Investors</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box></Box>
            <Box p="4">
              <input
                type="file"
                onChange={e => handlePreview(e)}
                accept=".csv,.xlsx,.xls"
                ref={fileInput}
              />
            </Box>
            <Spacer />
            <Box p="4"></Box>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => history.push('/preview')}
              disabled={previewInfo.length === 0}
              // isLoading={uploading}
              mr={5}
            >
              Preview
            </Button>
            <a href="https://greenhillscore.tradebuza.com/api/downloadinvestorexcel">
              <Button
              >
                Download Template (.csv)
              </Button>
            </a>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={uploading}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Uploading</ModalHeader>
          <ModalBody>
            <Progress colorScheme="whatsapp" hasStripe value={progress} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default BulkUpload;
