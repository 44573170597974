import React from 'react';
import {
    Flex,
    Box,
    Button,
    VStack,
    Heading
} from "@chakra-ui/react"
import { Formik, Form } from 'formik';
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';
import FormikControl from '../../utils/form/FormikControl';
import { changePasswordValidationSchema } from './helpers/validation';


const initialValues = {
    oldPassword: '',
    password: '',
    password2: '',
}

function ChangePassword() {


    const onSubmit = async (values, onSubmitProps) => {

    }
    return (
        <>
            <Flex>
                <Drawer />
                <Box w="100%">
                    <ProfileHeader />
                    <Box px={4} py={8}>
                        <Heading as="h6" size="sm">Change Password</Heading>
                        <br />
                        <Box w="50%">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={changePasswordValidationSchema}
                                onSubmit={onSubmit}
                            >
                                {(formik) => (
                                    <Form>
                                        <VStack spacing={6}>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="passord"
                                                    name="oldPassword"
                                                    placeholder="Enter current password"
                                                />
                                            </Box>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="password"
                                                    name="password"
                                                    placeholder="Enter password"
                                                />
                                            </Box>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="password"
                                                    name="password2"
                                                    placeholder="Confirm password"
                                                />
                                            </Box>
                                            <Flex justify="center">
                                                <Box w="100%">
                                                    <Button
                                                        disabled={formik.isSubmitting}
                                                        isLoading={formik.isSubmitting}
                                                        loadingText="Logging In"
                                                        type="submit"
                                                        bg="brand.primaryGreen"
                                                        borderColor="brand.primaryGreen"
                                                        color="white"
                                                        _active={{ bg: "brand.primaryGreen" }}
                                                        _hover={{ bg: "brand.primaryGreenHover" }}
                                                        size="lg">
                                                        Submit
                                                    </Button>
                                                </Box>
                                            </Flex>
                                        </VStack>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                </Box>
            </Flex>
        </>
    );
}

export default ChangePassword;