import React, { useContext } from "react";
import {
    AccordionItem,
    Accordion,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Stack,
    Box,
    Icon,
    Image,
    Flex
} from "@chakra-ui/react";
import { useLocation, Link } from 'react-router-dom';
import { AiOutlineUser, AiOutlineSetting } from 'react-icons/ai';
import { TiShoppingBag } from 'react-icons/ti';
import { GiReceiveMoney, GiTakeMyMoney } from 'react-icons/gi';
import { FiUser } from 'react-icons/fi';
import { RiDashboardLine } from 'react-icons/ri';
import { GrUserAdmin } from 'react-icons/gr';
import { FaMoneyCheck } from 'react-icons/fa';
import { Context } from '../../context/Context';
import Logo from '../../assets/logo.png';
import Can from '../../utils/rbac/Can';


const DesktopMenu = () => {

    const { user } = useContext(Context)

    const { rolestring: role } = user

    const location = useLocation()

    const activePath = location.pathname.split("/")[1]
    const secondaryActivePath = location.pathname.split("/")[2]

    return (
        <Stack
            borderRightWidth="1px"
            width={[
                "100%", // base
                "50%", // 480px upwards
                "25%", // 768px upwards
                "25%", // 992px upwards
            ]}
            height="100vh"
        >
            <Stack
                position="sticky"
                top={0}

            >
                <Box pb={2}>
                    <Flex justify="center">
                        <Image src={Logo} alt="Logo" w="70%" />
                    </Flex>
                </Box>

                <Accordion allowToggle borderColor="transparent">
                    <Can
                        role={role}
                        perform="dashboard:view"
                        yes={() => (
                            <AccordionItem
                                py={2}>
                                <Link to={`/dashboard`}>
                                    <AccordionButton
                                        bg={activePath === "dashboard" && "brand.lightGreen"}
                                        borderLeftWidth={"3px"}
                                        borderLeftColor={activePath === "dashboard" ? "brand.primaryGreen" : 'transparent'}

                                    >
                                        <Icon as={RiDashboardLine} mr={4} w={5} h={5} />
                                        <Box flex="1" textAlign="left">
                                            {/* <Icon as={AiOutlineEdit} mr={4} /> */}
                                            Dashboard
                                        </Box>
                                    </AccordionButton>
                                </Link>
                            </AccordionItem>

                        )}
                        no={() => null}
                    />
                    <Can
                        role={role}
                        perform="portfolio:view"
                        yes={() => (
                            <AccordionItem
                                py={2}>
                                <Link to={`/dashboard`}>
                                    <AccordionButton
                                        bg={activePath === "dashboard" && "brand.lightGreen"}
                                        borderLeftWidth={"3px"}
                                        borderLeftColor={activePath === "dashboard" ? "brand.primaryGreen" : 'transparent'}

                                    >
                                        <Icon as={TiShoppingBag} mr={4} w={5} h={5} />
                                        <Box flex="1" textAlign="left">
                                            {/* <Icon as={AiOutlineEdit} mr={4} /> */}
                                            Portfolio
                                        </Box>
                                    </AccordionButton>
                                </Link>
                            </AccordionItem>

                        )}
                        no={() => null}
                    />
                    <Can
                        role={role}
                        perform="users:view"
                        yes={() => (
                            <AccordionItem py={2}>
                                <Link to="/users">
                                    <AccordionButton
                                        bg={activePath === "users" && "brand.lightGreen"}
                                        // color={activePath === "users" && "brand.primaryGreen"}
                                        borderLeftWidth={"3px"}
                                        borderLeftColor={activePath === "users" ? "brand.primaryGreen" : 'transparent'}
                                        _hover={{ color: 'brand.primaryGreen' }}

                                    >
                                        <Icon as={AiOutlineUser} mr={4} w={5} h={5} />
                                        <Box
                                            flex="1" textAlign="left" fontWeight="lg">
                                            Users
                                        </Box>
                                    </AccordionButton>
                                </Link>
                            </AccordionItem>
                        )}
                        no={() => null}
                    />
                    <Can
                        role={role}
                        perform="investors:view"
                        yes={() => (
                            <AccordionItem py={2}>
                                <Link to={`/investors`}>
                                    <AccordionButton
                                        variant="ghost"
                                        bg={activePath === "investors" && "brand.lightGreen"}
                                        color={activePath === "investors" && "brand.green"}
                                        borderLeftWidth={"4px"}
                                        borderLeftColor={activePath === "investors" ? "brand.primaryGreen" : 'transparent'}
                                        _hover={{ color: 'brand.primaryGreen' }}

                                    >
                                        <Icon as={GiTakeMyMoney} mr={4} w={5} h={5} />
                                        <Box
                                            flex="1" textAlign="left" fontWeight="lg">
                                            Investors
                                        </Box>
                                    </AccordionButton>
                                </Link>
                            </AccordionItem>
                        )}
                        no={() => null}
                    />
                    <Can
                        role={role}
                        perform="transactions:view"
                        yes={() => (
                            <AccordionItem py={2}>
                                <Link to={`/transactions`}>
                                    <AccordionButton
                                        variant="ghost"
                                        bg={activePath === "transactions" && "brand.lightGreen"}
                                        color={activePath === "transactions" && "brand.green"}
                                        borderLeftWidth={"4px"}
                                        borderLeftColor={activePath === "transactions" ? "brand.primaryGreen" : 'transparent'}
                                        _hover={{ color: 'brand.primaryGreen' }}

                                    >
                                        <Icon as={FaMoneyCheck} mr={4} w={5} h={5} />
                                        <Box
                                            flex="1" textAlign="left" fontWeight="lg">
                                            Transactions
                                        </Box>
                                    </AccordionButton>
                                </Link>
                            </AccordionItem>
                        )}
                        no={() => null}
                    />

                    <Can
                        role={role}
                        perform="investments:view"
                        yes={() => (
                            <AccordionItem py={2}>
                                <Link to={`/investments`}>
                                    <AccordionButton
                                        variant="ghost"
                                        bg={activePath === "investments" && "brand.lightGreen"}
                                        color={activePath === "investments" && "brand.green"}
                                        borderLeftWidth={"4px"}
                                        borderLeftColor={activePath === "investments" ? "brand.primaryGreen" : 'transparent'}
                                        _hover={{ color: 'brand.primaryGreen' }}

                                    >
                                        <Icon as={GiReceiveMoney} mr={4} w={5} h={5} />
                                        <Box

                                            flex="1" textAlign="left" fontWeight="lg">
                                            Investments
                                        </Box>
                                    </AccordionButton>
                                </Link>
                            </AccordionItem>

                        )}
                        no={() => null}
                    />

                    <Can
                        role={role}
                        perform="investments-investor:view"
                        yes={() => (
                            <AccordionItem py={2}>
                                <Link to={`/user-investments`}>
                                    <AccordionButton
                                        variant="ghost"
                                        bg={activePath === "user-investments" && "brand.lightGreen"}
                                        color={activePath === "user-investments" && "brand.green"}
                                        borderLeftWidth={"4px"}
                                        borderLeftColor={activePath === "user-investments" ? "brand.primaryGreen" : 'transparent'}
                                        _hover={{ color: 'brand.primaryGreen' }}

                                    >
                                        <Icon as={GiReceiveMoney} mr={4} w={5} h={5} />
                                        <Box

                                            flex="1" textAlign="left" fontWeight="lg">
                                            Investment History
                                        </Box>
                                    </AccordionButton>
                                </Link>
                            </AccordionItem>

                        )}
                        no={() => null}
                    />
                    <Can
                        role={role}
                        perform="investors-profile:view"
                        yes={() => (
                            <AccordionItem py={2}>
                                <Link to={`/profile/edit`}>
                                    <AccordionButton
                                        variant="ghost"
                                        bg={activePath === "profile" && "brand.lightGreen"}
                                        color={activePath === "profile" && "brand.green"}
                                        borderLeftWidth={"4px"}
                                        borderLeftColor={activePath === "profile" ? "brand.primaryGreen" : 'transparent'}
                                        _hover={{ color: 'brand.primaryGreen' }}

                                    >
                                        <Icon as={FiUser} mr={4} w={5} h={5} />
                                        <Box

                                            flex="1" textAlign="left" fontWeight="lg">
                                            Profile
                                        </Box>
                                    </AccordionButton>
                                </Link>
                            </AccordionItem>

                        )}
                        no={() => null}
                    />

                    <Can
                        role={role}
                        perform="tenants:view"
                        yes={() => (
                            <AccordionItem py={2}>
                                <Link to={`/tenants`}>
                                    <AccordionButton
                                        variant="ghost"
                                        bg={activePath === "tenants" && "brand.lightGreen"}
                                        color={activePath === "tenants" && "brand.green"}
                                        borderLeftWidth={"4px"}
                                        borderLeftColor={activePath === "tenants" ? "brand.primaryGreen" : 'transparent'}
                                        _hover={{ color: 'brand.primaryGreen' }}

                                    >
                                        <Icon as={GrUserAdmin} mr={4} w={5} h={5} />
                                        <Box
                                            flex="1" textAlign="left" fontWeight="lg">
                                            Tenants
                                        </Box>

                                    </AccordionButton>
                                </Link>
                            </AccordionItem>
                        )}
                        no={() => null}
                    />
                    <Can
                        role={role}
                        perform="settings:view"
                        yes={() => (
                            <AccordionItem
                                py={2}>
                                <AccordionButton
                                    bg={activePath === "settings" && "brand.lightGreen"}
                                    color={activePath === "settings" && "brand.green"}
                                    borderLeftWidth={"4px"}
                                    borderLeftColor={activePath === "settings" ? "brand.primaryGreen" : 'transparent'}

                                >
                                    <Icon as={AiOutlineSetting} mr={4} w={5} h={5} />
                                    <Box flex="1" textAlign="left">
                                        {/* <Icon as={AiOutlineEdit} mr={4} /> */}
                                        Settings
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel py={4}>
                                    <Stack spacing={4} ml={10}>
                                        <Link to={`/settings/change-password`}>
                                            <Box flex="1"
                                                color={(secondaryActivePath === "change-password") ? "brand.primaryGreen" : 'text'}
                                                textAlign="left"
                                                _hover={{ color: 'brand.primaryGreen' }}>
                                                Change Password
                                            </Box>
                                        </Link>
                                        <Link to={`/settings/investment-windows`}>
                                            <Box flex="1"
                                                color={(secondaryActivePath === "investment-windows") ? "brand.primaryGreen" : 'text'}
                                                textAlign="left"
                                                _hover={{ color: 'brand.primaryGreen' }}>
                                                Investment Windows
                                            </Box>
                                        </Link>
                                    </Stack>
                                </AccordionPanel>
                            </AccordionItem>
                        )}
                        no={() => null}
                    />
                </Accordion>
            </Stack>
        </Stack>
    );
};

export default DesktopMenu;