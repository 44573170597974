import React from 'react';
import { Grid, GridItem, Box, Center, Image, VStack, Heading, Text, Button, Flex } from "@chakra-ui/react"
import { Formik, Form } from 'formik';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import LoginImage from '../../assets/login.svg';
import { resetPasswordValidationSchema } from './helpers/validation';
import { showToast } from '../../utils/toast';
import config from '../../config';
import FormikControl from '../../utils/form/FormikControl';


const initialValues = {
    password: '',
    password2: '',
}


const ResetPassword = () => {
    const location = useLocation()
    const history = useHistory()
    const resetcode = location.pathname.split("/")[2]


    const onSubmit = async (values, onSubmitProps) => {
        const body = {
            password: values.password,
            resetcode
        }
        try {
            const res = await axios.post(`${config.baseUrl}/resetpassword`, body)
            if (res.data.error) {
                showToast("error", res.data.message)
            }
            else {

                showToast("success", "Successfully changed your password")
                history.push('/')
            }
        }
        catch (e) {
            showToast("error", e.messager)
        }
        finally {
            onSubmitProps.setSubmitting(false)
        }
    }
    return (
        <>
            <Grid templateColumns="repeat(3, 1fr)" gap={0}>
                <GridItem colSpan={1}>
                    <Box w="100%" h="100vh">
                        <Image src={LoginImage} height="100vh" />
                    </Box>
                </GridItem>
                <GridItem colSpan={2}>
                    <Center w="100%" h="100vh">
                        <Box w="60%" p={4} minHeight="70vh">
                            <VStack spacing={4} align="flex-start">
                                <Heading as="h4" size="md">
                                    Get a new password.
                                </Heading>
                                <Text fontSize="sm">Let’s get you a new password</Text>
                            </VStack>
                            <Box w="100%" mt={10}>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={resetPasswordValidationSchema}
                                    onSubmit={onSubmit}
                                >
                                    {(formik) => (
                                        <Form>
                                            <VStack spacing={6}>
                                                <Box w="100%">
                                                    <FormikControl
                                                        control="input"
                                                        type="password"
                                                        name="password"
                                                        placeholder="Enter password"
                                                    />
                                                </Box>
                                                <Box w="100%">
                                                    <FormikControl
                                                        control="input"
                                                        type="password"
                                                        name="password2"
                                                        placeholder="Repeat password"
                                                    />
                                                </Box>
                                                <Flex justify="center">
                                                    <Box w="100%">
                                                        <Button
                                                            disabled={formik.isSubmitting}
                                                            isLoading={formik.isSubmitting}
                                                            loadingText="Sending..."
                                                            type="submit"
                                                            bg="brand.primaryGreen"
                                                            borderColor="brand.primaryGreen"
                                                            color="white"
                                                            _active={{ bg: "brand.primaryGreen" }}
                                                            _hover={{ bg: "brand.primaryGreenHover" }}
                                                            size="lg">
                                                            Reset
                                                        </Button>
                                                    </Box>
                                                </Flex>
                                            </VStack>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </Box>
                    </Center>
                </GridItem>
            </Grid>
        </>
    );
}

export default ResetPassword;