import * as Yup from 'yup';

export const changePasswordValidationSchema = Yup.object({
    oldPassword: Yup.string().required("Password is required"),
    password: Yup.string().required("Password is required"),
    password2: Yup.string().test('passwords-match', 'Passwords do not match', function (value) {
        return this.parent.password === value
    })
})




