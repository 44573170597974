import React from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Avatar,
    HStack,
    Text,
    Flex,
    Box,
} from "@chakra-ui/react"
import { AiOutlineEdit } from 'react-icons/ai';
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';

function Transactions() {
    return (
        <>
            <Flex>
                <Drawer />
                <Box w="100%">
                    <ProfileHeader />
                    <Box px={4} py={8}>
                        <Flex justify="flex-end">

                        </Flex>
                        <br />
                        <Box shadow="md" pb={8}>
                            <Table variant="striped" colorScheme="gray" size="md">
                                <Thead>
                                    <Tr>
                                        <Th>ID</Th>
                                        <Th>Name</Th>
                                        <Th>Email</Th>
                                        <Th>Phone</Th>
                                        <Th>Created Date</Th>
                                        <Th>View</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td>
                                            <HStack spacing={2}>
                                                <Avatar size="xs" />
                                                <Text>John</Text>
                                            </HStack>
                                        </Td>
                                        <Td>Doe</Td>
                                        <Td>jd@gmail.com</Td>
                                        <Td>07011640721</Td>
                                        <Td>25th Jan 2021</Td>
                                        <Td>
                                            <AiOutlineEdit />
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <HStack spacing={2}>
                                                <Avatar size="xs" />
                                                <Text>John</Text>
                                            </HStack>
                                        </Td>
                                        <Td>Doe</Td>
                                        <Td>jd@gmail.com</Td>
                                        <Td>07011640721</Td>
                                        <Td>25th Jan 2021</Td>
                                        <Td>
                                            <AiOutlineEdit />
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <HStack spacing={2}>
                                                <Avatar size="xs" />
                                                <Text>John</Text>
                                            </HStack>
                                        </Td>
                                        <Td>Doe</Td>
                                        <Td>jd@gmail.com</Td>
                                        <Td>07011640721</Td>
                                        <Td>25th Jan 2021</Td>
                                        <Td>
                                            <AiOutlineEdit />
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </Box>
                    </Box>
                </Box>
            </Flex>
        </>
    );
}

export default Transactions;