import React, { useContext, useEffect, useState, useCallback } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Stack,
  Switch,
  Text,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { mutate } from 'swr';
import { AiOutlineEdit } from 'react-icons/ai';
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';
import { Context } from '../../context/Context';
import { useInvestors } from './data/fetcher';
import { useInvestorSpecialPackages } from '../dashboard/helpers/fetcher';
import InvestorPackagesTable from './components/InvestorPackagesTable';
import { showToast } from '../../utils/toast';
import config from '../../config';
import BulkUpload from '../../components/upload/BulkUpload';

function Investors() {
  const { user } = useContext(Context);
  const [selectedInvestor, setSelectedInvestor] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const { data: investors, isLoading } = useInvestors(
    'fetchinvestors',
    user.token
  );
  const { data: packages, isLoading: loadingPackages } =
    useInvestorSpecialPackages(
      selectedInvestor
        ? `fetchinvestorspecialpackagesbyinvestorid/${selectedInvestor.id}`
        : null,
      user.token
    );
  const [openForm, setOpenForm] = useState(false);

  const displayUploadImagePage = () => {
    setOpenForm(true);
  };

  const hideUploadImagePage = () => {
    setOpenForm(false);
  };

  useEffect(() => {
    if (!selectedInvestor && investors.length) {
      setSelectedInvestor(investors[0]);
    }
  }, [investors, selectedInvestor]);

  const changeUserStatus = useCallback(
    async (id, status) => {
      const requestOptions = {
        headers: { Authorization: `Bearer ${user.token}` },
      };

      const endpoint = status ? 'activateuser' : 'deactivateuser';
      setLoadingStatus(id);
      try {
        const res = await axios.post(
          `${config.baseUrl}/${endpoint}`,
          { id },
          requestOptions
        );
        if (res.data.error) {
          showToast('error', res.data.error);
        } else {
          mutate([`${config.baseUrl}/fetchinvestors`, user.token]);
          showToast('success', 'Done');
        }
      } catch (e) {
        showToast('error', e.messager);
      } finally {
        setLoadingStatus(0);
      }
    },
    [user]
  );

  return (
    <>
      <Flex>
        <Drawer />
        <Box w="100%">
          <ProfileHeader />
          <Box px={4} py={8}>
            <Heading as="h4" size="md">
              Investors
            </Heading>
            {/* <Box bg="white" px={4} rounded="md">
             
              <HStack spacing={8} align="center">
                <Text color="white" fontSize="md">Filter By:</Text>
                <Select
                  w="25%"
                  placeholder="Category"
                  bg="brand.inputBg"
                  border="0"
                  focusBorderColor="brand.primaryGreen"
                >
                  <option value={"1"}>1</option>
                  <option value={"2"}>2</option>
                  <option value={"3"}>3</option>
                </Select>
                <Select
                  w="25%"
                  placeholder="Category"
                  bg="brand.inputBg"
                  border="0"
                  focusBorderColor="brand.primaryGreen"
                >
                  <option value={"1"}>1</option>
                  <option value={"2"}>2</option>
                  <option value={"3"}>3</option>
                </Select>
                <Select
                  w="25%"
                  placeholder="Category"
                  bg="brand.inputBg"
                  border="0"
                  focusBorderColor="brand.primaryGreen"
                >
                  <option value={"1"}>1</option>
                  <option value={"2"}>2</option>
                  <option value={"3"}>3</option>
                </Select>
              </HStack>
            </Box> */}
            <Flex justify="flex-end">
              <Link to="/investors/add">
                <Button
                  bg="brand.primaryGreen"
                  borderColor="brand.primaryGreen"
                  color="white"
                  _active={{ bg: 'brand.primaryGreen' }}
                  _hover={{ bg: 'brand.primaryGreenHover' }}
                  size="md"
                  mr={3}
                >
                  Add new investor
                </Button>
              </Link>

              <BulkUpload />
            </Flex>
            <br />
            <Flex>
              <Box
                w="25%"
                h="80vh"
                overflowY="scroll"
                shadow="md"
                borderWidth="1px"
              >
                {isLoading && (
                  <Box mt={12}>
                    <Center>
                      <Spinner color="brand.primaryGreen" />
                    </Center>
                  </Box>
                )}

                {investors.map(investor => {
                  return (
                    <Box
                      p="4"
                      key={investor.id}
                      onClick={() => {
                        setSelectedInvestor(investor);
                      }}
                      bg={
                        selectedInvestor?.id === investor.id
                          ? 'brand.midGreen'
                          : '#fff'
                      }
                      cursor="pointer"
                    >
                      <Stack spacing={3}>
                        <Flex justify="space-between">
                          <Text fontSize="sm">{investor.usercode}</Text>
                          <Text fontSize="xs">
                            {investor.statusstring.toUpperCase()}
                          </Text>
                        </Flex>

                        <Flex justify="space-between">
                          <HStack>
                            <Heading as="h6" size="xs">
                              {investor.firstname} {investor.lastname}
                            </Heading>
                            <Link to={`/investors/edit/${investor.id}`}>
                              <AiOutlineEdit style={{ fontSize: 18 }} />
                            </Link>
                          </HStack>

                          <Heading as="h6" size="xs">
                            {investor.gender ?? ''}
                          </Heading>
                        </Flex>
                        <Text fontSize="sm">{investor.email}</Text>
                      </Stack>
                    </Box>
                  );
                })}
              </Box>

              <Box w="75%" h="80vh" overflowY="scroll" shadow="md" px="4">
                {selectedInvestor && (
                  <Flex justify="space-between">
                    <HStack spacing={4}>
                      <Avatar
                        size="xl"
                        name={`${selectedInvestor.firstname} ${selectedInvestor.lastname}`}
                      />
                      <Stack spacing={2} align="flex-start">
                        <Text fontSize="sm">{selectedInvestor.usercode}</Text>
                        <Heading as="h6" size="xs">
                          {selectedInvestor.firstname}{' '}
                          {selectedInvestor.lastname}
                        </Heading>
                        <Text fontSize="sm">{selectedInvestor.email}</Text>

                        <Link
                          to={`/investments/subscribe/${selectedInvestor.id}`}
                        >
                          <Button
                            bg="brand.primaryGreen"
                            borderColor="brand.primaryGreen"
                            color="white"
                            _active={{ bg: 'brand.primaryGreen' }}
                            _hover={{ bg: 'brand.primaryGreenHover' }}
                            size="sm"
                          >
                            Subscribe to a package
                          </Button>
                        </Link>
                      </Stack>
                    </HStack>
                    <Stack spacing={3}>
                      <Text>
                        {selectedInvestor.statusstring === 'active'
                          ? 'Deactivate'
                          : 'Activate'}
                      </Text>
                      <Switch
                        size="lg"
                        colorScheme="whatsapp"
                        isChecked={selectedInvestor.statusstring === 'active'}
                        onChange={e =>
                          changeUserStatus(
                            selectedInvestor.id,
                            e.target.checked
                          )
                        }
                      />
                    </Stack>
                  </Flex>
                )}

                <br />
                <Divider orientation="horizontal" />
                <br />
                <InvestorPackagesTable
                  entity={selectedInvestor ? packages : []}
                  loading={loadingPackages}
                  investorId={selectedInvestor}
                  displayUploadImagePage={displayUploadImagePage}
                  hideUploadImagePage={hideUploadImagePage}
                />
              </Box>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </>
  );
}

export default Investors;
