import React, { useContext } from 'react';
import {
    Flex,
    Box,
    Button,
    HStack,
    VStack,
    Heading
} from "@chakra-ui/react"
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi'
import Drawer from '../layout/Drawer';
import FormikControl from '../../utils/form/FormikControl';
import { addProjectValidationSchema } from './helpers/validation';
import ProfileHeader from '../layout/ProfileHeader';
import { useWindows } from './helpers/fetcher';
import { Context } from '../../context/Context';


const initialValues = {
    projectname: '',
    roi: '',
    startdate: '',
    season: '',
    priceperunit: '',
    tenure: '',
    description: '',
    publishdate: '',
    type: '',
}

function AddProject() {

    const { user } = useContext(Context);

    const history = useHistory()


    const { data: windows } = useWindows(`fetchwindowsfortenant`, user.token)

    console.log(windows)


    const onSubmit = async (values, onSubmitProps) => {

    }
    return (
        <>
            <Flex>
                <Drawer />
                <Box w="100%">
                    <ProfileHeader />
                    <Box px={4} py={8}>
                        <HStack>
                            <Button colorScheme="teal" variant="link" onClick={() => history.goBack()}>
                                <BiArrowBack />
                            </Button>
                            <Heading as="h6" size="sm">Add Project</Heading>
                        </HStack>
                        <br />
                        <Box w="50%">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={addProjectValidationSchema}
                                onSubmit={onSubmit}
                            >
                                {(formik) => (
                                    <Form>
                                        <VStack spacing={6}>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    name="name"
                                                    placeholder="Enter name"
                                                    label="Name"
                                                />
                                            </Box>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="date"
                                                    name="startDate"
                                                    placeholder="Enter date"
                                                    label="Start Date"
                                                />
                                            </Box>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="textarea"
                                                    type="text"
                                                    name="description"
                                                    placeholder="Enter description"
                                                    label="Description"
                                                />
                                            </Box>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="date"
                                                    name="publishDate"
                                                    placeholder="Enter date"
                                                    label="Publish Date"
                                                />
                                            </Box>

                                            <Flex justify="center">
                                                <Box w="100%">
                                                    <Button
                                                        disabled={formik.isSubmitting}
                                                        isLoading={formik.isSubmitting}
                                                        loadingText="Logging In"
                                                        type="submit"
                                                        bg="brand.primaryGreen"
                                                        borderColor="brand.primaryGreen"
                                                        color="white"
                                                        _active={{ bg: "brand.primaryGreen" }}
                                                        _hover={{ bg: "brand.primaryGreenHover" }}
                                                        size="lg">
                                                        Submit
                                                </Button>
                                                </Box>
                                            </Flex>
                                        </VStack>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                </Box>
            </Flex>
        </>
    );
}

export default AddProject;