import axios from 'axios';
import useSWR from 'swr';
import config from '../../config'



export const fetcher = (url, token) => {
    return axios.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(res => res.data)
}


export const useSeasons = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    return {
        seasons: data ? data.seasons : [],
        isLoadingSeasons: !error && !data,
        isError: error,
    }
}


