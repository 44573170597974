import React, { useContext, useMemo } from 'react';
import { useTable } from 'react-table';
import { Link } from 'react-router-dom';
import { CSVLink } from "react-csv";
import {
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    VStack,
    Spinner,
    Button,
    Flex
} from '@chakra-ui/react';
import { AiOutlineDownload, AiOutlineEdit } from 'react-icons/ai';
import { Context } from '../../../context/Context';
import { useInvestorSpecialPackages } from '../../dashboard/helpers/fetcher';

function InvestorSpecials() {
    const { user } = useContext(Context);

    const { data: entity, isLoading } = useInvestorSpecialPackages(`fetchinvestorspecialpackagesbyinvestorid/${user.id}`, user.token)

    const columns = useMemo(
        () => [
            {
                Header: "Project Name",
                accessor: "specialPackageObject.packagename"
            },
            {
                Header: "ROI",
                accessor: "commission",

            },
            {
                Header: "Tenure",
                accessor: "specialPackageObject.tenure"
            },
            {
                Header: "Season",
                accessor: "specialPackageObject.season"
            },
            {
                Header: "Start Date",
                accessor: "specialPackageObject.startdate"
            },
            {
                Header: "End Date",
                accessor: "specialPackageObject.duedate"
            },
            {
                Header: "Edit",
                accessor: "specialPackageObject.id",
                show: false,
                Cell: ({ value, row }) => {
                    const { original } = row
                    const { editable } = original

                    if (editable) {
                        return (
                            <Link to={`/investments/special-packages/edit/${value}`}>
                                <Button>
                                    <AiOutlineEdit style={{ fontSize: 18 }} />
                                </Button>
                            </Link>
                        )
                    }
                    return (
                        <Button disabled>
                            <AiOutlineEdit style={{ fontSize: 18 }} />
                        </Button>
                    )
                }
            }
        ], []
    )

    const data = useMemo(() => entity || [], [entity])


    const tableInstance = useTable({ columns, data, initialState: { hiddenColumns: user.rolestring === 'investor' ? ['id'] : [] } })
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance

    return (
        <>
            <Flex justify="flex-end">
                <CSVLink data={data}>
                    <Button>
                        <AiOutlineDownload style={{ fontSize: 18, marginRight: 5 }} />
                        Download CSV
                    </Button>
                </CSVLink>
            </Flex>
            <br />
            <Table variant="simple" colorScheme="gray" size="md" {...getTableProps()}>
                <Thead>
                    {
                        headerGroups.map(headerGroup => (
                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map(column => (
                                        <Th {...column.getHeaderProps()}>{
                                            column.render('Header')}
                                        </Th>
                                    ))
                                }
                            </Tr>

                        ))
                    }
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {
                        rows.map(row => {
                            prepareRow(row)
                            return (
                                <Tr {...row.getRowProps()}>
                                    {
                                        row.cells.map(cell => {
                                            return (
                                                <Td {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </Td>
                                            )
                                        })
                                    }
                                </Tr>
                            )
                        })}
                </Tbody>
            </Table>
            {isLoading &&
                <VStack mt={4}>
                    <Spinner color="brand.primaryGreen" />
                </VStack>}
            {!isLoading && entity.length === 0 && <h6 style={{ textAlign: 'center' }}>No records found</h6>}
        </>
    );
}

export default InvestorSpecials;