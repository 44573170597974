import React, { useContext } from 'react';
import {
    Flex,
    Box,
    Button,
    HStack,
    Heading,
    SimpleGrid
} from "@chakra-ui/react"
import { Formik, Form } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios'
import { BiArrowBack } from 'react-icons/bi'
import Drawer from '../layout/Drawer';
import FormikControl from '../../utils/form/FormikControl';
import { addInvestorValidationSchema } from './data/validation';
import { showToast } from '../../utils/toast';
import config from '../../config';
import ProfileHeader from '../layout/ProfileHeader';
import { Context } from '../../context/Context';
import { useUser } from '../users/data/fetcher';

const inititalProfileValues = {
    firstname: '',
    middlename: '',
    lastname: '',
    email: '',
    phone: '',
    residenceno: '',
    // address: '',
    country: '',
    dob: '',
    state: '',
    postalcode: '',
    gender: '',
    accountname: '',
    accountnumber: '',
    bankname: '',
    bvn: '',
    nextofkin: '',
    nextofkinphone: '',
    nextofkincountry: '',
    nextofkinstate: '',
    nextofkinpostalcode: '',
    nextofkinresidenceno: '',
    nextofkinrelationship: '',
    nextofkinbvn: ''
}

function EditInvestor() {

    const { user } = useContext(Context)

    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` }
    }

    const history = useHistory()
    const location = useLocation()
    const investorId = location.pathname.split('/')[3]
    console.log(investorId)

    const { data } = useUser(`getuserbyid/${investorId}`, user.token)

    const savedProfileValues = Object.entries(data).length > 0 ? {
        firstname: data.firstname ?? '',
        middlename: data.middlename ?? '',
        lastname: data.lastname ?? '',
        email: data.email ?? '',
        phone: data.phone ?? '',
        residenceno: data.residenceno ?? '',
        // address: data.address ?? '',
        country: data.country ?? '',
        state: data.state ?? '',
        postalcode: data.postalcode ?? '',
        gender: data.gender ?? '',
        accountname: data.accountname ?? '',
        accountnumber: data.accountnumber ?? '',
        bankname: data.bankname ?? '',
        bvn: data.bvn ?? '',
        nextofkin: data.nextofkin ?? '',
        nextofkinphone: data.nextofkinphone ?? '',
        nextofkincountry: data.nextofkincountry ?? '',
        nextofkinstate: data.nextofkinstate ?? '',
        nextofkinpostalcode: data.nextofkinpostalcode ?? '',
        nextofkinresidenceno: data.nextofkinresidenceno ?? '',
        nextofkinrelationship: data.nextofkinrelationship ?? '',
        nextofkinbvn: data.nextofkinbvn ?? '',
        dob: data.dob ?? '',
    } : inititalProfileValues


    const onSubmitProfile = async (values, onSubmitProps) => {
        const body = { ...values, id: investorId }
        try {
            const res = await axios.post(`${config.baseUrl}/editinvestorprofile`, body, requestOptions)
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully updated investor")
                history.goBack()
            }
        }
        catch (e) {
            showToast("error", e.messager)
        }
        finally {
            onSubmitProps.setSubmitting(false)
        }
    }
    return (
        <>
            <Flex>
                <Drawer />
                <Box w="100%">
                    <ProfileHeader />
                    <Box px={4} py={8}>
                        <HStack>
                            <Button colorScheme="teal" variant="link" onClick={() => history.goBack()}>
                                <BiArrowBack />
                            </Button>
                            <Heading as="h6" size="sm">Edit Profile</Heading>
                        </HStack>
                        <br />
                        {/* <Box w="50%"> */}
                        <Formik
                            enableReinitialize={true}
                            initialValues={savedProfileValues}
                            validationSchema={addInvestorValidationSchema}
                            onSubmit={onSubmitProfile}
                        >
                            {(formik) => (
                                <Form>
                                    <SimpleGrid columns={2} spacing={10}>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                name="firstname"
                                                placeholder="First name"
                                                label="First Name"
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                name="middlename"
                                                placeholder="Middle name"
                                                label="Middle Name"
                                                required={false}
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                name="lastname"
                                                placeholder="Enter Last Name"
                                                label="Last Name"
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="email"
                                                name="email"
                                                placeholder="Enter email"
                                                label="Email"
                                            />
                                        </Box>

                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                name="phone"
                                                placeholder="Enter Phone Number"
                                                label="Phone Number"
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="date"
                                                name="dob"
                                                placeholder="Enter Date of Birth"
                                                label="Date of Birth"
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="select"
                                                label="Season"
                                                name="gender"
                                                placeholder="Select Gender"
                                                optionName="label"
                                                value="label"
                                                options={
                                                    [{ label: "Male" }, { label: "Female" }]
                                                }
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="textarea"
                                                type="text"
                                                name="residenceno"
                                                placeholder="Enter Residence Number"
                                                label="Residence Number"
                                            />
                                        </Box>

                                        {/* <Box w="100%">
                                            <FormikControl
                                                control="textarea"
                                                type="text"
                                                name="address"
                                                placeholder="Enter Address"
                                                label="Address"
                                            />
                                        </Box> */}
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                name="country"
                                                placeholder="Enter Country"
                                                label="Country"
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                name="state"
                                                placeholder="Enter State"
                                                label="State"
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                name="postalcode"
                                                placeholder="Enter Postcal Code"
                                                label="Postcal Code"
                                                required={false}
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                name="accountname"
                                                placeholder="Enter Account Name"
                                                label="Account Name"
                                                required={false}
                                            />
                                        </Box>

                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                name="accountnumber"
                                                placeholder="Enter Account Number"
                                                label="Account Number"
                                                required={false}
                                            />
                                        </Box>

                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                name="bankname"
                                                placeholder="Enter Bank Name"
                                                label="Bank Name"
                                                required={false}
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="number"
                                                name="bvn"
                                                placeholder="Enter BVN"
                                                label="BVN"
                                                required={false}
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                name="nextofkin"
                                                placeholder="Enter Next of Kin"
                                                label="Next of Kin"
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="number"
                                                name="nextofkinphone"
                                                placeholder="Enter Next of Kin Phone Number"
                                                label="Next of Kin Phone Number"
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                name="nextofkinrelationship"
                                                placeholder="Enter Next of King Phone Relationship"
                                                label="Relationship"
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="textarea"
                                                type="text"
                                                name="nextofkinresidenceno"
                                                placeholder="Enter Next of Kin Residence"
                                                label="Next of Kin Residence"
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                name="nextofkincountry"
                                                placeholder="Enter Next of Kin Country"
                                                label="Next of Kin Country"
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                name="nextofkinstate"
                                                placeholder="Enter Next of Kin State"
                                                label="Next of Kin State"
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="number"
                                                name="nextofkinpostalcode"
                                                placeholder="Enter Next of Kin Postal Code"
                                                label="Next of Kin Postal Code"
                                                required={false}
                                            />
                                        </Box>
                                        <Box w="100%">
                                            <FormikControl
                                                control="input"
                                                type="number"
                                                name="nextofkinbvn"
                                                placeholder="Enter Next of Kin BVN"
                                                label="Next of Kin BVN"
                                            />
                                        </Box>
                                    </SimpleGrid>

                                    <br />
                                    <Flex justify="center">
                                        <Box w="100%">
                                            <Button
                                                disabled={formik.isSubmitting}
                                                isLoading={formik.isSubmitting}
                                                loadingText="Submitting"
                                                type="submit"
                                                bg="brand.primaryGreen"
                                                borderColor="brand.primaryGreen"
                                                color="white"
                                                _active={{ bg: "brand.primaryGreen" }}
                                                _hover={{ bg: "brand.primaryGreenHover" }}
                                                size="lg">
                                                Submit
                                            </Button>
                                        </Box>
                                    </Flex>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Flex>
        </>
    );
}

export default EditInvestor;