import * as Yup from 'yup';

export const addWindowValidationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    seasonid: Yup.string().required("Season is required"),
    startdate: Yup.string().required("Start Date is required"),
    duedate: Yup.string().required("Due Date is required"),
    payoutdate: Yup.string().required("Payout Date is required"),
    tenure: Yup.string().required("Tenure is required"),
})



