import React, { useMemo, useContext, useState, useCallback } from 'react';
import {
    Switch,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Avatar,
    HStack,
    VStack,
    Spinner,
    Button,
    Box,
} from '@chakra-ui/react';
import { mutate } from 'swr';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AiOutlineEdit } from 'react-icons/ai';
import { useTable } from 'react-table'
import { showToast } from '../../../utils/toast';
import config from '../../../config';
import { Context } from '../../../context/Context';


const UsersTable = ({ entity, loading }) => {

    const { user } = useContext(Context)
    const [loadingStatus, setLoadingStatus] = useState(0)


    const changeUserStatus = useCallback(async (id, status) => {
        const requestOptions = {
            headers: { "Authorization": `Bearer ${user.token}` }
        }

        const endpoint = status ? 'activateuser' : 'deactivateuser';
        setLoadingStatus(id)
        try {
            const res = await axios.post(`${config.baseUrl}/${endpoint}`, { id }, requestOptions)
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                mutate([`${config.baseUrl}/getusers`, user.token])
                showToast("success", "Done")
            }
        }
        catch (e) {
            showToast("error", e.messager)
        }
        finally {
            setLoadingStatus(0)
        }
    }, [user])

    const superAdminColumns = useMemo(
        () => [
            {
                Header: "First Name",
                accessor: "firstname",
                Cell: ({ value }) => {
                    return (
                        <HStack spacing={2} >
                            <Avatar size="xs" />
                            <span>{value}</span>
                        </HStack >

                    )

                }

            },
            {
                Header: "Last Name",
                accessor: "lastname"
            },
            {
                Header: "Email",
                accessor: "email",

            },
            {
                Header: "Phone",
                accessor: "phone"
            },

            {
                Header: "Edit",
                accessor: "id",
                Cell: ({ value }) => {
                    return (
                        <Link to={`/users/edit/${value}`}>
                            <Button>
                                <AiOutlineEdit style={{ fontSize: 18 }} />
                            </Button>
                        </Link>
                    )
                }
            }
        ], []
    )


    const columns = useMemo(
        () => [
            {
                Header: "First Name",
                accessor: "firstname",
                Cell: ({ value }) => {
                    return (
                        <HStack spacing={2} >
                            <Avatar size="xs" />
                            <span>{value}</span>
                        </HStack >

                    )

                }

            },
            {
                Header: "Last Name",
                accessor: "lastname"
            },
            {
                Header: "Email",
                accessor: "email",

            },
            {
                Header: "Phone",
                accessor: "phone"
            },
            {
                Header: "Status",
                accessor: "statusstring",
                Cell: ({ value, row }) => {
                    const { original } = row
                    return (
                        <HStack>
                            <Switch
                                size="lg"
                                colorScheme="whatsapp"
                                isChecked={value === "active"}
                                onChange={(e) => changeUserStatus(original.id, e.target.checked)}
                            />
                            <Box w={2}>
                                {loadingStatus === original.id && <Spinner size="xs" colorScheme="whatsapp" />}
                            </Box>
                        </HStack>
                    )
                }
            },
            {
                Header: "Edit",
                accessor: "id",
                Cell: ({ value }) => {
                    return (
                        <Link to={`/users/edit/${value}`}>
                            <Button>
                                <AiOutlineEdit style={{ fontSize: 18 }} />
                            </Button>
                        </Link>
                    )

                }
            }
        ], [loadingStatus, changeUserStatus]
    );

    const data = useMemo(() => entity || [], [entity])

    const isSuperAdmin = user.role === 1


    const tableInstance = useTable({ columns: isSuperAdmin ? superAdminColumns : columns, data })
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance


    return (
        <>
            <Table variant="simple" colorScheme="gray" size="md" {...getTableProps()}>
                <Thead>
                    {
                        headerGroups.map(headerGroup => (
                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map(column => (
                                        <Th {...column.getHeaderProps()}>{
                                            column.render('Header')}
                                        </Th>
                                    ))
                                }
                            </Tr>

                        ))
                    }
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {
                        rows.map(row => {
                            prepareRow(row)
                            return (
                                <Tr {...row.getRowProps()}>
                                    {
                                        row.cells.map(cell => {
                                            return (
                                                <Td {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </Td>
                                            )
                                        })
                                    }
                                </Tr>
                            )
                        })}
                </Tbody>
            </Table>
            {loading &&
                <VStack mt={4}>
                    <Spinner color="brand.primaryGreen" />
                </VStack>}
            {!loading && entity.length === 0 && <h6 style={{ textAlign: 'center' }}>No records found</h6>}
        </>
    );
}

export default UsersTable;