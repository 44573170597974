import * as Yup from 'yup';

export const loginValidationSchema = Yup.object({
    email: Yup.string().email("The email address you entered is invalid").required("Email is required"),
    password: Yup.string().required("Password is required")
})

export const signupValidationSchema = Yup.object({
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    email: Yup.string().email("The email address you entered is invalid").required("Email is required"),
    password: Yup.string().required("Password is required"),
    password2: Yup.string().test('passwords-match', 'Passwords do not match', function (value) {
        return this.parent.password === value
    })
})

export const forgotPasswordValidationSchema = Yup.object({
    email: Yup.string().email("The email address you entered is invalid").required("Email is required")
})

export const resetPasswordValidationSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    password2: Yup.string().test('passwords-match', 'Passwords do not match', function (value) {
        return this.parent.password === value
    })
})




