import React, { useContext, useState, useEffect } from 'react';
import {
    Grid,
    Flex,
    Box,
    Button,
    Text,
    Heading,
    Icon,
    Center,
    VStack,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    List, ListItem, ListIcon
} from "@chakra-ui/react"
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { MdCheckCircle } from 'react-icons/md';
import axios from 'axios';
// import { WiDayCloudyGusts } from 'react-icons/wi';
import { useLocation } from 'react-router-dom';
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';
import { useSpecials } from './helpers/fetcher';
import { Context } from '../../context/Context';
import { formatNumber } from '../../utils/numberFormatter';
import { showToast } from '../../utils/toast';
import config from '../../config';


function SubscribeToPackage() {

    const { user } = useContext(Context);
    const location = useLocation()

    const investorId = location.pathname.split('/')[3];

    const { data: entity, isLoading, isError } = useSpecials(`fetchsubscribablespecialpackagesbyinvestorid/${investorId}`, user.token)
    const [selectedPackage, setSelectedPackage] = useState(null)
    const [unit, setUnit] = useState(1)
    const [loading, setLoading] = useState(false)


    const { isOpen, onOpen, onClose } = useDisclosure()

    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` },
    }


    useEffect(() => {
        if (!selectedPackage) {
            onClose()
        }
        else {
            onOpen()
        }
    }, [selectedPackage, onOpen, onClose])


    const updateUnit = (operation) => {
        if (operation === "dec") {
            setUnit(Math.max(1, unit - 1));
        }
        else {
            setUnit(unit + 1);
        }
    }


    const subscribe = async () => {

        const body = {
            specialpackageid: selectedPackage.id,
            amountinvested: parseInt(selectedPackage.priceperunit) * unit,
            numberofunits: unit,
            userid: investorId
        }

        setLoading(true)

        try {
            const res = await axios.post(`${config.baseUrl}/adminsubscribespecialpackageforinvestor`, body, requestOptions)
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully added special package")
            }
        }
        catch (e) {
            showToast("error", e.message)
        }
        finally {
            setSelectedPackage(null)
            setLoading(false)
        }

    }


    const renderData = () => {
        if (isLoading) {
            return (
                <Box
                    mb="4"
                    p="8"
                    rounded="md"
                    w="100%"
                    minHeight="200px"

                    shadow="base"
                >
                    <Center>
                        <Spinner size="xl" color="green.500" />
                    </Center>
                </Box>
            )
        }
        else if (isError) {
            return <Box
                mb="4"
                p="8"
                rounded="md"
                w="100%"
                minHeight="200px"

                shadow="base"
            >
                <Center>
                    <Text>An Error Occured</Text>

                </Center>

            </Box>
        }
        else if (!isError && !entity.length) {
            return <Box
                mb="4"
                p="8"
                rounded="md"
                w="100%"
                minHeight="200px"

                shadow="base"
            >

                <Center>
                    <Text>No Data Found</Text>
                </Center>
            </Box>
        }
        else {
            return <Grid templateColumns="repeat(3, 1fr)" gap={6}>{entity.map(data => {
                return (
                    <Box w="100%" minHeight="60" p={4} shadow="md" key={data.id}>
                        <Heading as="h2" size="xl" color="brand.greyTextColor">{data.packagename}</Heading>

                        <Box mt={8}>
                            <Heading as="h3" size="lg" color="brand.greyTextColor">N {formatNumber(data.priceperunit)}</Heading>
                        </Box>
                        <br />
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={MdCheckCircle} color="green.500" />
                                {data.commission}% Commission
                        </ListItem>
                            <ListItem>
                                <ListIcon as={MdCheckCircle} color="green.500" />
                                {data.farmsize} Acre
                        </ListItem>
                            <ListItem>
                                <ListIcon as={MdCheckCircle} color="green.500" />
                                {data.season}
                            </ListItem>
                        </List>
                        <Box mt={10}>
                            <Button colorScheme="blue" variant="link" onClick={() => setSelectedPackage(data)}>
                                Subscribe
                            </Button>
                        </Box>
                    </Box>
                );
            })}</Grid>
        }
    }

    return (
        <>
            <Flex>
                <Drawer />
                <Box w="100%">
                    <ProfileHeader />
                    <Box px={4} py={8}>
                        <Heading mb={8} color="brand.greyTextColor">Investments</Heading>
                        {renderData()}

                        {/* modal */}

                        <Modal isOpen={isOpen} onClose={() => {
                            setSelectedPackage(null)
                        }}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Modal Title</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <Flex justify="space-between" align="center">
                                        <VStack>
                                            <Text fontSize="sm" color="brand.greyTextColor">
                                                Unit
                                            </Text>
                                            <Flex justify="space-between" align="center" w="40">
                                                <Button
                                                    variant="ghost"
                                                    onClick={() => updateUnit("dec")}
                                                >
                                                    <Icon as={AiOutlineMinusCircle} color="red.500" w={6} h={6} />
                                                </Button>

                                                <Heading>{unit}</Heading>
                                                <Button
                                                    variant="ghost"
                                                    onClick={() => updateUnit("inc")}
                                                >
                                                    <Icon as={AiOutlinePlusCircle} color="green.500" w={6} h={6} onClick={() => updateUnit("inc")} />
                                                </Button>

                                            </Flex>
                                        </VStack>
                                        <VStack>
                                            <Text fontSize="sm" color="brand.greyTextColor">
                                                Total Amount
                                            </Text>
                                            <Flex justify="space-between" align="center">
                                                <Heading as="h6" size="xs">{selectedPackage && formatNumber(parseInt(selectedPackage.priceperunit) * unit)}</Heading>
                                            </Flex>
                                        </VStack>
                                    </Flex>
                                </ModalBody>

                                <ModalFooter>
                                    <Button disabled={loading} colorScheme="blue" mr={3} onClick={() => setSelectedPackage(null)}>
                                        Close
                                    </Button>
                                    <Button
                                        isLoading={loading}
                                        onClick={subscribe}
                                        variant="ghost">Subscribe</Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                        {/* <Tabs variant="unstyled">
                            <TabList>
                                <Tab _selected={{ color: "white", bg: "brand.primaryGreen" }}>Special Packages</Tab>
                                <Tab _selected={{ color: "white", bg: "brand.primaryGreen" }}>Projects</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>



                                </TabPanel>
                                <TabPanel>
                                    <Grid templateColumns="repeat(3, 1fr)" gap={6}>

                                        <Box w="100%" minHeight="60" p={4} shadow="md">
                                            <Heading as="h5" size="sm">1 Acre</Heading>
                                            <Box mt={8}>
                                                <Heading>N 694,000</Heading>
                                            </Box>
                                            <br />
                                            <List spacing={3}>
                                                <ListItem>
                                                    <ListIcon as={MdCheckCircle} color="green.500" />
                                                   3% Off
                                                </ListItem>
                                                <ListItem>
                                                    <ListIcon as={MdCheckCircle} color="green.500" />
                                                    Free Farm Set-Up
                                                </ListItem>
                                                <ListItem>
                                                    <ListIcon as={MdCheckCircle} color="green.500" />
                                                   Farm Management Services
                                                </ListItem>
                                            </List>
                                            <Box mt={10}>
                                                <Link to='/user-investments/1'>
                                                    <Button leftIcon={<AiOutlineArrowRight />} colorScheme="blue" variant="link">
                                                        View Details
                                                    </Button>
                                                </Link>
                                            </Box>
                                        </Box>
                                        <Box w="100%" minHeight="60" p={4} shadow="md">
                                            <Heading as="h5" size="sm">1 Acre</Heading>
                                            <Box mt={8}>
                                                <Heading>N 694,000</Heading>
                                            </Box>
                                            <br />
                                            <List spacing={3}>
                                                <ListItem>
                                                    <ListIcon as={MdCheckCircle} color="green.500" />
                                                   3% Off
                                                </ListItem>
                                                <ListItem>
                                                    <ListIcon as={MdCheckCircle} color="green.500" />
                                                    Free Farm Set-Up
                                                </ListItem>
                                                <ListItem>
                                                    <ListIcon as={MdCheckCircle} color="green.500" />
                                                   Farm Management Services
                                                </ListItem>
                                            </List>
                                            <Box mt={10}>
                                                <Link to='/user-investments/1'>
                                                    <Button leftIcon={<AiOutlineArrowRight />} colorScheme="blue" variant="link">
                                                        View Details
                                                    </Button>
                                                </Link>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </TabPanel>
                            </TabPanels>
                        </Tabs> */}
                    </Box>
                </Box>
            </Flex>
        </>
    );
}

export default SubscribeToPackage;