import React, {
  useMemo,
  useRef,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  VStack,
  Spinner,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Progress,
  Box,
  Flex,
  Spacer,
  Textarea,
  ModalCloseButton,
  Text,
  Grid,
  Select,
} from '@chakra-ui/react';
import axios from 'axios';
import { useTable } from 'react-table';
import { formatNumber } from '../../../utils/numberFormatter';
import { showToast } from '../../../utils/toast';
import config from '../../../config';

import { Context } from '../../../context/Context';
import { useAllImages } from '../../dashboard/helpers/fetcher';
import { mutate } from 'swr';
import moment from 'moment';

const InvestorPackagesTable = ({ entity, loading, investorId }) => {
  const { user } = useContext(Context);
  const fileInput = useRef();
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [show, setShow] = useState(false);
  const [displayId, setDisplayId] = useState(null);
  const [imageInfo, setImageInfo] = useState('');
  const [description, setDescription] = useState('');
  const [openForm, setOpenForm] = useState(false);
  const [allSpecialPakages, setAllSpecialPakages] = useState([]);
  const [packageId, setPackageId] = useState('');

  // const { data: images, isLoading: loadingImages } = useAllImages(
  //   displayId?.investorspecialpackageid
  //     ? `fetchallinvestorsimagesbyinvestoridandinvestorspecialpackageid/${investorId.id}/${displayId.investorspecialpackageid}`
  //     : null,
  //   user.token
  // );

  const { data: images } = useAllImages(
    displayId?.investorspecialpackageid
      ? `fetchimagesbyspecialpackagebyid/${displayId.investorspecialpackageid}`
      : null,
    user.token
  );

  // console.log('images', images);

  const handleClick = useCallback((investorspecialpackageid, userid) => {
    setShow(true);
    setDisplayId({ investorspecialpackageid, userid });
    console.log('test', { investorspecialpackageid, userid });
  }, []);

  const uploadFile = useCallback(
    async (file, investorspecialpackageid, userid) => {
      setUploading(true);
      const data = new FormData();
      data.append('file', file);
      data.append('description', description);
      // data.append('userid', userid);
      data.append('investorspecialpackageid', packageId);

      const requestOptions = {
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      };

      try {
        const res = await axios.post(
          `${config.baseUrl}/uploadimagesforinvestors`,
          data,
          requestOptions
        );
        if (res.data.error) {
          showToast('error', res.data.error);
        } else {
          mutate([
            `${config.baseUrl}/fetchimagesbyspecialpackagebyid/${displayId.investorspecialpackageid}`,
            user.token,
          ]);
          showToast('success', 'Successfully added image');
          setImageInfo(res.data.image);
        }
      } catch (e) {
        showToast('error', e.message);
      } finally {
        setUploading(false);
      }
    },
    [user.token, description, packageId]
  );

  const deleteImage = async id => {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      const res = await axios.post(
        `${config.baseUrl}/deleteinvestorimage/${id}`,
        data,
        requestOptions
      );
      if (res.data.error) {
        showToast('error', res.data.error);
      } else {
        mutate([
          `${config.baseUrl}/fetchimagesbyspecialpackagebyid/${displayId.investorspecialpackageid}`,
          user.token,
        ]);
        showToast('success', 'Successfully deleted image');
        setImageInfo(res.data.image);
      }
    } catch (e) {
      showToast('error', e.message);
    }
  };

  const getAllSpecialPakages = async () => {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    try {
      const res = await axios.get(
        `${config.baseUrl}/fetchallspecialpackages`,
        requestOptions
      );
      if (res.data.error) {
        // showToast('error', res.data.error);
      } else {
        setAllSpecialPakages(res?.data?.specialPackages);
      }
    } catch (e) {
      showToast('error', e.message);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Project Name',
        accessor: 'specialPackageObject.packagename',
      },
      {
        Header: 'Season',
        accessor: 'specialPackageObject.season',
      },
      {
        Header: 'Amount',
        accessor: 'amountinvested',
        Cell: ({ value }) => {
          return <span>{formatNumber(value)}</span>;
        },
      },
      {
        Header: 'Units',
        accessor: 'numberofunits',
        Cell: ({ value }) => {
          return <span>{formatNumber(value)}</span>;
        },
      },
      {
        Header: 'Interest',
        accessor: 'specialPackageObject.commission',
      },
      {
        Header: 'Commission',
        accessor: 'commission',
        Cell: ({ value }) => {
          return <span>{formatNumber(value)}</span>;
        },
      },
      {
        Header: 'Total',
        accessor: 'total',
        Cell: ({ value }) => {
          return <span>{formatNumber(value)}</span>;
        },
      },
      // {
      //   Header: 'Upload',
      //   accessor: 'specialPackageObject',
      //   Cell: ({ value, row }) => {
      //     const { original } = row;
      //     return (
      //       <>
      //         {/* <Button onClick={() => fileInput.current.click()}>Upload</Button>
      //                       <input
      //                           type="file"
      //                           onChange={(e) => uploadFile(e.target.files[0], value.id, original.userid)}
      //                           ref={fileInput}
      //                           accept="image/*"
      //                           style={{ display: 'none' }}
      //                       /> */}
      //         <Button onClick={() => handleClick(value.id, original.userid)}>
      //           see more
      //         </Button>
      //       </>
      //     );
      //   },
      // },
    ],
    [handleClick]
  );

  useEffect(() => {
    getAllSpecialPakages();
  }, [description]);

  const data = useMemo(() => entity || [], [entity]);

  const tableInstance = useTable({ columns, data });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <>
      {!show ? (
        <Table
          variant="simple"
          colorScheme="gray"
          size="md"
          {...getTableProps()}
        >
          <Thead>
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <>
          {/* <Flex> */}
          <Button onClick={() => setOpenForm(true)}>open modal</Button>
          <Modal isOpen={openForm} onClose={() => setOpenForm(false)}>
            <ModalOverlay />

            <ModalContent>
              <ModalHeader>Upload Image</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <Textarea
                    onChange={e => setDescription(e.target.value)}
                    placeholder="Add Image Description"
                    size="sm"
                  />
                </Box>
                <Box w="100%">
                  <Select
                    placeholder="Select Package"
                    size="lg"
                    w="100%"
                    mt={5}
                    value={packageId}
                    onChange={e => {
                      setPackageId(e.target.value);
                      // console.log(e.target.value);
                    }}
                    required
                  >
                    {allSpecialPakages &&
                      allSpecialPakages.map((data, index) => (
                        <option key={index} value={data.id}>
                          {data.packagename}
                        </option>
                      ))}
                    {/* <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option> */}
                  </Select>
                </Box>
                <Box>
                  <Button
                    w="100%"
                    mt={5}
                    onClick={() => fileInput.current.click()}
                    disabled={packageId === ''}
                  >
                    Upload
                  </Button>
                  <input
                    type="file"
                    onChange={e => {
                      uploadFile(
                        e.target.files[0],
                        displayId.investorspecialpackageid,
                        displayId.userid
                      );
                      setOpenForm(false);
                    }}
                    ref={fileInput}
                    accept="image/*"
                    style={{ display: 'none' }}
                  />
                </Box>
                <Spacer />
                <Box p="4"></Box>
              </ModalBody>
            </ModalContent>
          </Modal>
          <Button ml={5} onClick={() => setShow(false)}>Go Back</Button>

          {/* <>
              <Box w={400} h={400} p={6}>
                <img src={imageInfo.imageurl} alt={imageInfo.description} />
              </Box>
              <VStack>
                <Text>{imageInfo.description}</Text>
              </VStack>
            </> */}

          <Grid templateColumns="repeat(3, 1fr)" gap={10}>
            {images.map(image => (
              <Box w="300px" h="300px" my={10}>
                <img
                  style={{
                    marginTop: '8px',
                    verticalAlign: 'middle',
                    width: '300px',
                    height: '300px',
                  }}
                  key={image.id}
                  src={image.imageurl}
                  alt={image.imagename}
                />
                <Flex>
                  <Box p="2">
                    <Text>{image.description}</Text>
                  </Box>
                  <Spacer />
                  <Box p="2">
                    <Text color="green.500">
                      {moment(image.created_at).format('MMM Do YY')}
                    </Text>
                  </Box>
                </Flex>
                <Button
                  bg="red.500"
                  color="white"
                  onClick={() => deleteImage(image.id)}
                >
                  Delete
                </Button>
              </Box>
            ))}
          </Grid>

          {/* {images?.map(image => (
            <>
              <Box w={400} h={400} p={6}>
                <img src={image.imageurl} alt={image.description} />
              </Box>
              <VStack>
                <Text>{image.description}</Text>
              </VStack>
            </>
          ))} */}
          {/* </Flex> */}
        </>
      )}
      {loading && (
        <VStack mt={10}>
          <Spinner color="brand.primaryGreen" />
        </VStack>
      )}
      {!loading && entity.length === 0 && (
        <h6 style={{ textAlign: 'center', marginTop: 10 }}>No records found</h6>
      )}
      <Modal isOpen={uploading}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Uploading</ModalHeader>
          <ModalBody>
            <Progress colorScheme="whatsapp" hasStripe value={progress} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InvestorPackagesTable;
