import React from 'react';
import { Field } from 'formik';
import { FormControl, FormLabel, Heading, InputGroup, Input, FormErrorMessage, InputLeftAddon } from '@chakra-ui/react';

function ChakraInput(props) {
    const { label, name, prefix, required, ...rest } = props;

    return (
        <Field name={name}>
            {(props) => {
                const { field, form } = props
                return (
                    <FormControl
                        isInvalid={form.errors[name] && form.touched[name]}
                        isRequired={required === false ? required : true}
                    >
                        {
                            label &&
                            <FormLabel htmlFor={name} mb={2}>
                                <Heading as="a" fontSize="sm">{label}</Heading>
                            </FormLabel>
                        }

                        {prefix ?
                            <InputGroup >
                                <InputLeftAddon children={prefix} />
                                <Input
                                    {...field}
                                    {...rest}
                                    focusBorderColor="brand.primaryGreen" />
                            </InputGroup>
                            :
                            <Input
                                {...field}
                                {...rest}
                                height="50px"
                                focusBorderColor="brand.primaryGreen" />
                        }
                        <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
                    </FormControl>
                )
            }}
        </Field >
    )
}

export default ChakraInput;