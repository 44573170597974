import React, { useContext } from 'react';
import {
    Flex,
    Box,
    Button,
    HStack,
    VStack,
    Heading,
    Grid
} from "@chakra-ui/react"
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { BiArrowBack } from 'react-icons/bi'
import Drawer from '../layout/Drawer';
import { showToast } from '../../utils/toast';
import config from '../../config';
import FormikControl from '../../utils/form/FormikControl';
import { addUserValidationSchema } from './data/validation';
import ProfileHeader from '../layout/ProfileHeader';
import { Context } from '../../context/Context';


const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
}

function CreateUser() {

    const { user } = useContext(Context)

    const history = useHistory()

    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` }
    }

    const onSubmit = async (values, onSubmitProps) => {
        try {
            const res = await axios.post(`${config.baseUrl}/createadmin`, values, requestOptions)
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully created user")
                history.goBack()
            }
        }
        catch (e) {
            showToast("error", e.messager)
        }
        finally {
            onSubmitProps.setSubmitting(false)
        }
    }
    return (
        <>
            <Flex>
                <Drawer />
                <Box w="100%">
                    <ProfileHeader />
                    <Box px={4} py={8}>
                        <HStack>
                            <Button colorScheme="teal" variant="link" onClick={() => history.goBack()}>
                                <BiArrowBack />
                            </Button>
                            <Heading as="h6" size="sm">Create User</Heading>
                        </HStack>
                        <br />
                        {/* <Box w="50%"> */}
                        <Formik
                            initialValues={initialValues}
                            validationSchema={addUserValidationSchema}
                            onSubmit={onSubmit}
                        >
                            {(formik) => (
                                <Form>
                                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                        <VStack spacing={6}>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    name="firstname"
                                                    placeholder="First name"
                                                    label="First Name"
                                                />
                                            </Box>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter email"
                                                    label="Email"
                                                />
                                            </Box>
                                        </VStack>
                                        <VStack spacing={6}>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    name="lastname"
                                                    placeholder="Enter Last Name"
                                                    label="Last Name"
                                                />
                                            </Box>
                                            <Box w="100%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    name="phone"
                                                    placeholder="Enter Phone Number"
                                                    label="Phone Number"
                                                />
                                            </Box>
                                        </VStack>
                                    </Grid>
                                    <br />
                                    <Flex justify="center">
                                        <Box w="100%">
                                            <Button
                                                disabled={formik.isSubmitting}
                                                isLoading={formik.isSubmitting}
                                                loadingText="Creating..."
                                                type="submit"
                                                bg="brand.primaryGreen"
                                                borderColor="brand.primaryGreen"
                                                color="white"
                                                _active={{ bg: "brand.primaryGreen" }}
                                                _hover={{ bg: "brand.primaryGreenHover" }}
                                                size="lg">
                                                Submit
                                            </Button>
                                        </Box>
                                    </Flex>
                                </Form>
                            )}
                        </Formik>
                        {/* </Box> */}
                    </Box>
                </Box>
            </Flex>
        </>
    );
}

export default CreateUser;