import * as Yup from 'yup';

export const addTenantValidationSchema = Yup.object({
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Email address is not valid").required("Email address is required"),
    tenantname: Yup.string().required("Tenant Name is required"),
    phone: Yup.string().required("Phone Number"),
})




