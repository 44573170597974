import React, { useMemo } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    VStack,
    Spinner,
    Button
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { AiOutlineEdit } from 'react-icons/ai';

import { useTable } from 'react-table'


const InvestmentWindowsTable = ({ entity, loading }) => {

    const columns = useMemo(
        () => [
            {
                Header: "Title",
                accessor: "title",
            },
            {
                Header: "Season",
                accessor: "season"
            },
            {
                Header: "Start Date",
                accessor: "startdate",

            },
            {
                Header: "Due Date",
                accessor: "duedate"
            },
            {
                Header: "Payout Date",
                accessor: "payoutdate"
            },
            {
                Header: "Edit",
                accessor: "id",
                Cell: ({ value }) => {

                    return (
                        <Link to={`/settings/investment-windows/edit/${value}`}>
                            <Button>
                                <AiOutlineEdit style={{ fontSize: 18 }} />
                            </Button>
                        </Link>
                    )
                }
            }


        ], []
    );

    const data = useMemo(() => entity || [], [entity])


    const tableInstance = useTable({ columns, data })
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance


    return (
        <>
            <Table variant="simple" colorScheme="gray" size="md" {...getTableProps()}>
                <Thead>
                    {
                        headerGroups.map(headerGroup => (
                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map(column => (
                                        <Th {...column.getHeaderProps()}>{
                                            column.render('Header')}
                                        </Th>
                                    ))
                                }
                            </Tr>

                        ))
                    }
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {
                        rows.map(row => {
                            prepareRow(row)
                            return (
                                <Tr {...row.getRowProps()}>
                                    {
                                        row.cells.map(cell => {
                                            return (
                                                <Td {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </Td>
                                            )
                                        })
                                    }
                                </Tr>
                            )
                        })}
                </Tbody>
            </Table>
            {loading &&
                <VStack mt={4}>
                    <Spinner color="brand.primaryGreen" />
                </VStack>}
            {!loading && entity.length === 0 && <h6 style={{ textAlign: 'center' }}>No records found</h6>}
        </>
    );
}

export default InvestmentWindowsTable;