import React, { useState } from 'react';
import {
    Flex,
    Box,
    Button,
    Text,
    Stack,
    HStack,
    Heading,
    // Divider,
    Progress,
    Grid,
    VStack,
    Icon
} from "@chakra-ui/react"
import { AiOutlineInfoCircle, AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import Drawer from '../layout/Drawer';
import ProfileHeader from '../layout/ProfileHeader';

function InvestmentDetails() {
    const [unit, setUnit] = useState(1)


    const updateUnit = (operation) => {
        if (operation === "dec") {
            setUnit(Math.max(1, unit - 1));
        }
        else {
            setUnit(unit + 1);
        }
    }
    return (
        <>
            <Flex>
                <Drawer />
                <Box w="100%">
                    <ProfileHeader />
                    <Box px={4} py={8}>
                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                            <Box w="100%" borderWidth="1px" minHeight="100vh">
                                <Stack spacing={4} p={4}>
                                    <Text fontSize="sm" color="brand.greyTextColor" textAlign="center">
                                        Greenhills
                                    </Text>
                                    <Heading textAlign="center" as="h3" size="lg" color="brand.primaryGreen">Greenhills Farm Crystal Story</Heading>
                                    <Flex justify="center" align="center">
                                        <HStack>
                                            <Icon as={AiOutlineInfoCircle} />
                                            <Text fontSize="sm" color="brand.greyTextColor">
                                                Project Details
                                        </Text>
                                        </HStack>
                                    </Flex>

                                    <Box p={4} borderWidth="1px" rounded="md">
                                        <Grid templateColumns="repeat(4, 1fr)" gap={8}>
                                            <VStack spacing={2}>
                                                <Text fontSize="sm" color="brand.greyTextColor">
                                                    Season
                                                </Text>
                                                <Heading as="h5" size="sm">Rainy</Heading>
                                            </VStack>
                                            <VStack spacing={2}>
                                                <Text fontSize="sm" color="brand.greyTextColor">
                                                    Start Date
                                                </Text>
                                                <Heading as="h5" size="sm">01/02/21</Heading>
                                            </VStack>
                                            <VStack spacing={2}>
                                                <Text fontSize="sm" color="brand.greyTextColor">
                                                    End Date
                                                </Text>
                                                <Heading as="h5" size="sm">01/02/21</Heading>
                                            </VStack>
                                            <VStack spacing={2}>
                                                <Text fontSize="sm" color="brand.greyTextColor">
                                                    Payout Date
                                                </Text>
                                                <Heading as="h5" size="sm">01/02/21</Heading>
                                            </VStack>
                                        </Grid>
                                    </Box>
                                    <Progress value={88} size="sm" colorScheme="green" />
                                    <Heading textAlign="center" as="h6" size="xs">12% Remaining</Heading>
                                </Stack>
                                <Stack spacing={4} p={4}>
                                    <Flex justify="space-between" align="center">
                                        <VStack>
                                            <Text fontSize="sm" color="brand.greyTextColor">
                                                Unit
                                            </Text>
                                            <Flex justify="space-between" align="center" w="40">
                                                <Button
                                                    variant="ghost"
                                                    onClick={() => updateUnit("dec")}
                                                >
                                                    <Icon as={AiOutlineMinusCircle} color="red.500" w={6} h={6} />
                                                </Button>

                                                <Heading>{unit}</Heading>
                                                <Button
                                                    variant="ghost"
                                                    onClick={() => updateUnit("inc")}
                                                >
                                                    <Icon as={AiOutlinePlusCircle} color="green.500" w={6} h={6} onClick={() => updateUnit("inc")} />
                                                </Button>

                                            </Flex>
                                        </VStack>
                                        <VStack>
                                            <Text fontSize="sm" color="brand.greyTextColor">
                                                Est. Yield Capacity
                                            </Text>
                                            <Flex justify="space-between" align="center">
                                                <Heading as="h6" size="xs">200 per unit Area / 6 months</Heading>
                                            </Flex>
                                        </VStack>
                                    </Flex>
                                </Stack>
                                <Stack spacing={4} p={4}>
                                    <Heading as="h6" size="xs" color="brand.greyTextColor">TERMS AND CONDITIONS</Heading>
                                    <Text fontSize="sm" color="brand.greyTextColor">
                                        The future is in our hands to shape.  The future is in our hands to shape. The future is in our hands to shape. The future is in our hands to shape.
                                    </Text>
                                    <Button
                                        w="100%"
                                        loadingText="Logging In"
                                        type="submit"
                                        bg="brand.primaryGreen"
                                        borderColor="brand.primaryGreen"
                                        color="white"
                                        _active={{ bg: "brand.primaryGreen" }}
                                        _hover={{ bg: "brand.primaryGreenHover" }}
                                        size="lg">
                                        Sponsor
                                    </Button>
                                </Stack>
                            </Box>
                            <Box w="100%">
                                <VStack spacing={4} align="flex-start">
                                    <Heading as="h4" size="md">Special Package Details</Heading>
                                    <Box>
                                        <VStack spacing={4} align="flex-start">
                                            <Heading color="brand.lightGreyTextColor" as="h4" size="md">Greenhills Farm Crystal Story</Heading>
                                            <Text fontSize="sm" color="brand.greyTextColor">
                                                The future is in our hands to shape.  The future is in our hands to shape. The future is in our hands to shape. The future is in our hands to shape.
                                            </Text>
                                        </VStack>
                                    </Box>
                                    <Box>
                                        <VStack spacing={4} align="flex-start">
                                            <Heading color="brand.lightGreyTextColor" as="h4" size="md">Soybean Trading</Heading>
                                            <Text fontSize="sm" color="brand.greyTextColor">
                                                The future is in our hands to shape.  The future is in our hands to shape. The future is in our hands to shape. The future is in our hands to shape.
                                            </Text>
                                        </VStack>
                                    </Box>
                                </VStack>
                            </Box>
                        </Grid>
                    </Box>
                </Box>
            </Flex>
        </>
    );
}

export default InvestmentDetails;